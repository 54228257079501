import { useFetchJobByIdQuery } from 'services/jobs/job.query';
import {
  useFetchCompensationQuery,
  useFetchDefaultPitchesQuery,
  useFetchEducationQuery,
  useFetchEmployeeTypeQuery,
  useFetchWorkTypeQuery,
} from 'services/common/constants.query';
import {
  useFetchMyClientListQuery,
  useLazyFetchMyClientListQuery,
} from 'services/client/client.query';
import { getClient } from '../utils/selectors';
import { getLabelValueFormat } from 'utils/querySelector';
import { useEffect, useMemo } from 'react';
import { extractProtocolAndRest, getDefaultInitialValue } from '../utils/util';
import { DEFAULT_VALUES } from '../constants/defaults';
import { useFetchUsersQuery } from 'services/user/users.query';
import { useDispatch } from 'react-redux';
import { setClientId } from 'store/client.slice';

export default function usePreFillInitialValues(jobId: number) {
  const dispatch = useDispatch();
  //FETCH DATA
  const {
    data: jobDetail,
    isSuccess,
    isFetching: isLoading,
  } = useFetchJobByIdQuery(
    { id: jobId },
    { skip: !Boolean(jobId) && isNaN(jobId), refetchOnMountOrArgChange: true }
  ); //don't fetch for when opened for create

  const { data: defaultPitches } = useFetchDefaultPitchesQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => ({
        data: data
          ? data.reduce((acc: Record<number, typeof curr>, curr) => {
              acc[curr.id] = curr;
              return acc;
            }, {})
          : {},
        ...other,
      }),
    }
  );
  const { data: clientList } = useFetchMyClientListQuery(
    {
      data: {
        filters: ['is_active'],
        page: 1,
        take: 50,
        query: '',
        client_id: jobDetail?.data?.client_id,
      },
    },
    {
      selectFromResult: ({ data, ...other }) => {
        return {
          data: data ? getClient(data.data) : [],
          ...other,
        };
      },
    }
  );

  //Fetch constants
  const { data: workTypeList } = useFetchWorkTypeQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => {
        return {
          data: data ? getLabelValueFormat(data, 'id', 'name') : [],
          ...other,
        };
      },
    }
  );
  const { data: educationList } = useFetchEducationQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => {
        return {
          data: data ? getLabelValueFormat(data, 'id', 'name') : [],
          ...other,
        };
      },
    }
  );
  const { data: compensationList } = useFetchCompensationQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => {
        return {
          data: data ? getLabelValueFormat(data, 'id', 'name') : [],
          ...other,
        };
      },
    }
  );
  const { data: employeeTypeList } = useFetchEmployeeTypeQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => {
        return {
          data: data ? getLabelValueFormat(data, 'id', 'name') : [],
          ...other,
        };
      },
    }
  );
  const { data: userList } = useFetchUsersQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => {
        if (data) {
          return {
            data: data
              ? data?.map((user) => ({
                  label: `${user.first_name} ${user.last_name}`,
                  value: user.email,
                }))
              : [],
            ...other,
          };
        }
        return { data: [], ...other };
      },
    }
  );

  //CONSOLIDATE DATA
  const initialValues = useMemo(() => {
    //edit data pre-fill
    const defaultValues = getDefaultInitialValue({
      defaultPitch: defaultPitches[1],
    });
    if (jobId && isSuccess) {
      const {
        data: jobData,
        bcc: { bcc, cc },
        skills,
        pitch,
        jobAds,
        primary,
        recruiters,
      } = jobDetail;
      const selectedClient = clientList.find(
        (client) => client.value === jobData.client.id
      );
      const emailList = selectedClient?.email?.concat(userList) ?? [];

      const jobLinkExtraction = extractProtocolAndRest(jobData.client_job_link);
      return {
        //Client detail
        job_type: `${jobData?.job_type_id}`,
        client: {
          label: jobData?.client?.name,
          value: jobData?.client?.id,
        },
        managerList: selectedClient?.manager,
        contact: {
          label: `${jobData?.lead?.first_name} ${jobData?.lead?.last_name}`,
          value: jobData?.lead?.id,
        },
        location: {
          value: jobData?.location_id,
          label: `${jobData?.location?.city ?? ''}, ${
            jobData?.location?.state ?? ''
          }, ${jobData?.location?.zip_code ?? ''}, ${
            jobData?.location?.country?.country ?? ''
          }`,
        },
        industry: {
          label: selectedClient?.industry?.specification,
          value: selectedClient?.industry?.id,
        },
        company_website: selectedClient?.company_website,
        cc: emailList.filter((email) => cc?.includes(email?.value)),
        bcc: emailList.filter((email) => bcc?.includes(email?.value)),

        //Job Detail
        client_job_title: jobData?.title ?? '',
        no_of_opening: jobData?.opening <= 0 ? 1 : jobData?.opening,
        experience_level: {
          label: jobData?.experience_level?.label,
          value: jobData?.experience_level?.id,
        },
        education: educationList?.find(
          (data) => data?.value === jobData?.education_id
        ),
        work_type: workTypeList?.find(
          (data) => data.value === jobData.work_type_id
        ),
        job_link_type: jobLinkExtraction?.protocol
          ? {
              label: jobLinkExtraction?.protocol,
              value: jobLinkExtraction?.protocol,
            }
          : {
              value: 'https://', //default
              label: 'https://',
            },
        job_link_domain: jobLinkExtraction?.rest,
        employment_type: employeeTypeList?.find(
          (data) => data?.value === (jobData?.employment_type_id ?? 1)
        ),
        compensation_type: compensationList?.find(
          (data) => data?.value === (jobData?.compensation_id ?? 2)
        ),
        salary_range_start: `${jobData?.salary_range_start}`,
        salary_range_end: `${jobData?.salary_range_end}`,
        skills: getLabelValueFormat(skills, 'skill_id', 'skill'),
        client_job_description: jobData?.description,
        client_note: jobData?.notes ?? '',
        //Recruitment team
        account_manager: {
          label: `${jobData?.user?.first_name ?? ''} ${
            jobData?.user?.last_name ?? ''
          }`,
          value: jobData?.user?.id,
        },
        primary_recruiter: {
          label: `${primary?.recruiter?.first_name ?? ''} ${
            primary?.recruiter?.last_name ?? ''
          }`,
          value: primary?.recruiter?.id,
        },
        recruiters: recruiters?.map((recruiter) => ({
          recruiter: {
            label: `${recruiter?.recruiter?.first_name ?? ''} ${
              recruiter?.recruiter?.last_name ?? ''
            }`,
            value: recruiter?.recruiter?.id,
          },
          post_new_job_ads: recruiter?.post_new_job_ads,
          split: recruiter?.split,
          review: recruiter?.no_submittal_review
            ? 'no_submittal_review'
            : recruiter?.review_send_as_client_owner
            ? 'review_send_as_client_owner'
            : recruiter?.review_send_as_client_recruiter
            ? 'review_send_as_client_recruiter'
            : '',
          interview: recruiter?.interview_send_as_recruiter
            ? 'interview_send_as_recruiter'
            : recruiter?.interview_send_as_client_owner
            ? 'interview_send_as_client_owner'
            : '',
        })),

        //Job Ads
        posting_title: jobAds[0]?.title ?? jobData?.title ?? '',
        city: jobAds[0]?.city ?? jobData?.location?.city ?? '',
        state: jobAds[0]?.state ?? jobData?.location?.state ?? '',
        country: jobData?.location?.country?.country
          ? {
              label: jobData?.location?.country?.country,
              value: jobData?.location?.country?.code,
            }
          : '',
        zip_code: jobAds[0]?.zip_code ?? jobData?.location?.zip_code ?? '',
        career_page: jobAds[0]?.career_page_publish ?? false,
        show_profile: jobAds[0]?.show_profile ?? false,
        paid_job: jobAds[0]?.paid_job_board_publish ?? false,
        free_job: jobAds[0]?.free_job_board_publish ?? false,
        job_description:
          jobAds[0]?.description ?? DEFAULT_VALUES.job_ad.job_description,

        //Job campaign
        campaign_status: {
          label: jobData?.is_campaign ? 'Active' : 'Inactive',
          value: jobData?.is_campaign,
        },
        subject: pitch?.subject ?? '',
        body: pitch?.content ?? '',
      };
    }

    //default values
    return defaultValues;
  }, [
    clientList,
    compensationList,
    educationList,
    employeeTypeList,
    isSuccess,
    jobDetail,
    jobId,
    workTypeList,
    defaultPitches,
    userList,
  ]);
  useEffect(() => {
    if (isSuccess) {
      dispatch(setClientId({ client_id: jobDetail.data.client_id }));
    }
  }, [isSuccess]);
  return { initialValues, isLoading };
}
