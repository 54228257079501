import './emailBody.css';

import React, { useState, useEffect } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Box } from '@chakra-ui/react';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import ListItem from '@tiptap/extension-list-item';
import Link from '@tiptap/extension-link';
import Code from '@tiptap/extension-code';
import Image from '@tiptap/extension-image';
import Youtube from '@tiptap/extension-youtube';
import Document from '@tiptap/extension-document';
import TextStyle from '@tiptap/extension-text-style';
import Toolbar from './Toolbar';
import PlaceholderNode from './PlaceholderNode';
import {
  replacePlaceholdersWithSpans,
  replaceSpansWithPlaceholders,
} from './utils';
import CustomWrapper from './CustomWrapper';

interface AtsEmailBodyInterface {
  key?: number;
  onChange: any;
  invalid?: boolean;
  initialValue?: string;
  value?: string;
  id?: any;
  height?: any;
  menubar?: boolean;
  placeholder?: string;
  disabled?: boolean;
  refocusTo?: string;
  hasAIGenerate: string;
  setHasAIGenerate: any;
  setIsGenerate: any;
  setIsError: any;
  handleValues: (subject: string, body: string) => void;
  subject: string;
  body: string;
  allowedCategory?: any;
  attachments?: any;
  setAttachments?: any;
  isHideAttach?: boolean;
}

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      borderStyle: {
        default: '1px solid #ced4da',
        parseHTML: (element) =>
          element.style.borderStyle || '1px solid #ced4da',
        renderHTML: (attributes) => ({
          style: `border: ${attributes.borderStyle}`,
        }),
      },
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => ({
          'data-background-color': attributes.backgroundColor,
          style: `background-color: ${attributes.backgroundColor}`,
        }),
      },
    };
  },
});

const AtsEmailBody: React.FC<AtsEmailBodyInterface> = ({
  onChange,
  value,
  disabled,
  hasAIGenerate,
  setHasAIGenerate,
  setIsGenerate,
  setIsError,
  handleValues,
  subject,
  body,
  allowedCategory,
  isHideAttach,
}) => {
  const [text, setText] = useState('');
  const editor = useEditor({
    extensions: [
      Highlight.configure({ multicolor: true }),
      StarterKit.configure({
        paragraph: false,
      }),
      // CustomParagraph,
      TableRow,
      TableHeader,
      CustomTableCell,
      Document,
      Color,
      Code,
      Underline,
      BulletList,
      Paragraph,
      Text,
      TextStyle,
      OrderedList,
      ListItem,
      Youtube,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Table.configure({
        resizable: true,
        HTMLAttributes: {
          cellSpacing: '0',
          lineHeight: '0',
        },
      }),
      Link.configure({
        openOnClick: false,
        autolink: true,
        protocols: ['https', 'http', 'ftp', 'mailto'],
      }),
      Image.configure({
        inline: true,
      }),
      PlaceholderNode,
    ],
    content: '',

    onUpdate: ({ editor }) => {
      // Ensure updates happen only when not disabled
      handleTextChange(editor.getHTML());
    },
  });

  useEffect(() => {
    if (value !== text) {
      setText(value);
      //as value pass-down, replace added custom break with <p></p> for break line
      const content = replacePlaceholdersWithSpans(value).replace(
        /<br class="custom-break"\/?>/g,
        '<p></p>'
      );
      editor.commands.setContent(content);
    }
  }, [value]);

  const handleTextChange = (e: string) => {
    if (disabled) return;

    //replace <div></div> or <p></p> with custom <br/> to display break line
    //for pages like Job ads, Career page
    const content = replaceSpansWithPlaceholders(e)
      .replace(/<div><\/div>/g, '<br class="custom-break"/>')
      .replace(/<p><\/p>/g, '<br class="custom-break"/>');

    setText(content);

    if (onChange) {
      onChange(content);
    }
  };

  return (
    <>
      <Box
        sx={{
          '&& .tiptap.ProseMirror': {
            padding: '12px 15px',
            outline: 'none',
            color: disabled ? '#828283' : '',
            pointerEvents: disabled ? 'none' : 'auto', // Prevent interaction when disabled
          },
          '&& .tiptap.ProseMirror ProseMirror-focused': {
            border: 'none',
          },
          '&&.ProseMirror': {
            'font-family': 'verdana',
            'font-size': '14px',
          },
          display: 'inline-block',
          cursor: disabled ? 'not-allowed' : '',
        }}
        h="100%"
        w="100%"
        overflow="scroll"
      >
        <EditorContent editor={editor} />
      </Box>
      <Toolbar
        editor={editor}
        hasAIGenerate={hasAIGenerate}
        setHasAIGenerate={setHasAIGenerate}
        setIsGenerate={setIsGenerate}
        handleValues={handleValues}
        setIsError={setIsError}
        subject={subject}
        body={body}
        allowedCategory={allowedCategory}
        isHideAttach={isHideAttach}
      />
    </>
  );
};

export default AtsEmailBody;

function replacePTagsWithDiv(htmlString: string) {
  // Use a regular expression to match <p> and </p> tags
  return htmlString.replace(/<\/?p>/g, (tag) => {
    if (tag === '<p>') return '<div>';
    if (tag === '</p>') return '</div>';
    return tag;
  });
}
