import { useFetchMyClientListQuery } from 'services/client/client.query';
import { getClient } from '../utils/selectors';
import { useFetchDefaultPitchesQuery } from 'services/common/constants.query';
import { getDefaultInitialValue } from '../utils/util';
import { useSelector } from 'react-redux';

type ValueType = {
  label: string;
  value: number;
};

export default function useCreateInitialValue(
  clientValue?: ValueType,
  contactValue?: ValueType
) {
  const user = useSelector((state: any) => state.auth);
  const { data: defaultPitches } = useFetchDefaultPitchesQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => ({
        data: data
          ? data.reduce((acc: Record<number, typeof curr>, curr) => {
              acc[curr.id] = curr;
              return acc;
            }, {})
          : {},
        ...other,
      }),
    }
  );
  const { data: client } = useFetchMyClientListQuery(
    {
      data: {
        filters: ['is_active'],
        page: 1,
        take: 50,
        query: '',
        client_id: clientValue?.value, //default selected client display
      },
    },
    {
      selectFromResult: ({ data, ...other }) => {
        return {
          data: data ? getClient(data.data) : [],
          ...other,
        };
      },
      skip: !clientValue?.value,
    }
  ); //fetch only when form opened in place where Client should be pre-selected

  return getDefaultInitialValue({
    defaultPitch: defaultPitches[1],
    clientValue: client[0]
      ? { label: client[0]?.label, value: client[0]?.value }
      : '',
    contactValue: contactValue || '',
    industry: client[0]
      ? {
          label: client[0]?.industry?.specification,
          value: client[0]?.industry?.id,
        }
      : '',
    account_manager: client[0]?.manager?.[0],
    primary_recruiter: client[0]?.manager?.[0],
    contactList: client[0]?.contact ?? [],
    emailList: client[0]?.email ?? [],
    managerList: client[0]?.manager ?? [],
    company_website: client[0]?.company_website ?? '',
    user: { label: `${user.first_name} ${user.last_name}`, value: user.email },
  });
}
