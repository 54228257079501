import * as Yup from 'yup';

const createSMTPValidation = Yup.object()
  .shape({
    username: Yup.string().required('Username is required!'),
    password: Yup.string().required('Password is required'),
    smtp_host: Yup.string().required('SMTP hostname is required'),
    smtp_port: Yup.string().matches(/^\d+$/).required('SMTP port is required'),
    imap_host: Yup.string().required('IMAP hostname is required'),
    imap_port: Yup.string().matches(/^\d+$/).required('IAMP port is required'),
  })
  .required('All fields are required');

export type createSMTPInputs = Yup.InferType<typeof createSMTPValidation>;
export default createSMTPValidation;
