/* eslint-disable react-hooks/exhaustive-deps */

import { useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export const useCandidatesDrawerServices = () => {
  const { sequenceCandidateData } = useSelector((state: any) => state.outreach);
  const candidateID = sequenceCandidateData?.id;

  const [candidateIds, setCandidateIds] = useState([]);

  const {
    isOpen: isOpenChangeStatusModal,
    onOpen: onOpenChangeStatusModal,
    onClose: onCloseChangeStatusModal,
  } = useDisclosure({ defaultIsOpen: false });

  const handleViewDetails = () =>
    window.open(
      `/candidates/search/${candidateID}/about?total=1&rowId=0&page=1&take=10 `,
      '_blank'
    );

  return {
    candidateIds,
    setCandidateIds,
    isOpenChangeStatusModal,
    onOpenChangeStatusModal,
    onCloseChangeStatusModal,
    handleViewDetails,
  };
};
