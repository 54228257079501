import { Node, mergeAttributes } from '@tiptap/core';
import {
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from '@tiptap/react';

const CustomWrapper = Node.create({
  name: 'customWrapper',
  group: 'block', // Allows it to be a block-level element
  content: 'block+', // Allows multiple blocks inside

  parseHTML() {
    return [{ tag: 'div.custom-wrapper' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'custom-wrapper' }),
      0,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(NodeViewRenderer);
  },
});

function NodeViewRenderer() {
  return (
    <NodeViewWrapper>
      {/* ✅ Ensure this wrapper is truly editable */}
      <NodeViewContent />
    </NodeViewWrapper>
  );
}

export default CustomWrapper;
