import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getCandidateLogs,
  useCandidateActivityLogsMutation,
} from 'store/candidateActivity';
import { ActivityData, GroupedActivitiesData, LogPlacement } from './types';

// PICTURES
import AssociateJob from '../../../../../assets/images/associateJob.svg';
import Tags from '../../../../../assets/images/tags.svg';
import Call from '../../../../../assets/images/phone.svg';
import Note from '../../../../../assets/images/note.svg';
import Interview from '../../../../../assets/images/vidCam.svg';
import UpdateCandidate from '../../../../../assets/images/updateCandidate.svg';
import Email from '../../../../../assets/images/emailSent.svg';
import Change from '../../../../../assets/images/change.svg';
import CandidateSubmitted from '../../../../../assets/images/candidateSubmitted.svg';
import AddCandidate from '../../../../../assets/images/addCandidate.svg';
import SMS from '../../../../../assets/images/chatBubble.svg';
import { useDisclosure } from '@chakra-ui/react';
import {
  useGetJobsMutation,
  useViewJobPostPlacementMutation,
} from 'store/jobs.slice';
import { useViewClientMutation } from 'store/client.slice';

export const useActivityServices = () => {
  const filterList = [
    'All',
    'Email',
    'SMS',
    'Calls',
    'Forms',
    'Other Activity',
  ];
  const [checkedFilters, setCheckedFilters] = useState<string[]>(['0']);
  const {
    isOpen: isOpenPlacement,
    onOpen: onOpenPlacement,
    onClose: onClosePlacement,
  } = useDisclosure({ defaultIsOpen: false });
  const [placementData, setPlacementData] = useState<LogPlacement>(
    {} as LogPlacement
  );
  const { candidateLogsPgBtn } = useSelector(
    (state: any) => state.candidateActivity
  );

  const [activities, setActivities] = useState<GroupedActivitiesData>({});
  const [origActivities, setOrigActivities] = useState<ActivityData[]>([]);

  const { candidateData } = useSelector((state: any) => state.candidates);

  const [reqLogs, resLogs] = useCandidateActivityLogsMutation();

  const params = useParams();
  const candidateID =
    candidateData?.id || params.candidatesId || params.candidateId;

  useEffect(() => {
    reqLogs({ id: candidateID, data: candidateLogsPgBtn });
  }, [candidateID]);

  const handleCallback = () => {
    reqLogs({ id: candidateID, data: candidateLogsPgBtn });
  };

  const ActivityIcon = {
    associate: AssociateJob,
    tag: Tags,
    call: Call,
    note: Note,
    interview: Interview,
    update: UpdateCandidate,
    email: Email,
    change: Change,
    submit: CandidateSubmitted,
    create: AddCandidate,
    forms: AddCandidate,
    sms: SMS,
    others: UpdateCandidate,
  } as any;

  const ActivityTypeDict = {
    upload: { new: 'create', update: 'change' } as any,
    primary: { update: 'change' } as any,
    associate: {
      associate: 'associate',
      reject: 'change',
      change_sub_status: 'change',
      change_status: 'change',
      //    remove delete once migration is complete
      delete: 'associate',
      remove: 'associate',
      campaign: 'associate',
      create: 'create',
    } as any,
    email: {
      submit_to_client: 'submit',
      send: 'email',
      receive: 'email',
    } as any,
    tag: { tag: 'tag' } as any,
    create: { create: 'create' } as any,
    interview: { interview: 'interview' } as any,
    delete: { soft_delete: 'update' as any, delete: 'update' } as any,
    update: {
      update: 'update' as any,
      first_name: 'update' as any,
      job_title: 'update' as any,
      job_company: 'update' as any,
      quick_update: 'update' as any,
      work_status: 'update',
    } as any,
    hide: { hide: 'update' } as any,
    merge: { merge: 'update' } as any,
    ringcentral: { call: 'calls', sms: 'sms' } as any,
    forms: { forms: 'forms' } as any,
    note: { note: 'note' } as any,
  } as any;

  const ActivityFilterDict = [
    null,
    ['email', 'submit'],
    ['sms'],
    ['call'],
    ['forms'],
    [
      'associate',
      'tag',
      'call',
      'note',
      'interview',
      'update',
      'change',
      'create',
    ],
  ] as any;

  const handleIcons = (type: string, action: string) => {
    if (type && action) {
      const formattedType = type?.toLowerCase();
      const formattedAction = action?.toLowerCase()?.replaceAll(' ', '_');
      if (ActivityTypeDict?.[formattedType]?.[formattedAction]) {
        const icon = ActivityTypeDict?.[formattedType]?.[formattedAction];
        if (ActivityIcon[icon]) {
          return ActivityIcon[icon];
        } else {
          return ActivityIcon['others'];
        }
      } else {
        return ActivityIcon['others'];
      }
    } else {
      return ActivityIcon['others'];
    }
  };

  const handleCheckFilter = (index: string) => {
    if (checkedFilters.includes(index)) {
      setCheckedFilters(
        checkedFilters.filter((filter: string) => filter != index)
      );
    } else {
      setCheckedFilters([...checkedFilters, index]);
    }
  };

  const handleActivityType = (type: string, action: string) => {
    if (type && action) {
      const formattedType = type?.toLowerCase();
      const formattedAction = action?.toLowerCase()?.replaceAll(' ', '_');
      if (ActivityTypeDict?.[formattedType]?.[formattedAction]) {
        return ActivityTypeDict?.[formattedType]?.[formattedAction];
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const groupActivitiesByDate = (
    activities: ActivityData[]
  ): Record<string, ActivityData[]> => {
    return activities.reduce((groups, activity) => {
      const formattedDate = moment(activity.created_at).format('MM/DD/YYYY'); // Extract date only (MM/DD/YYYY)
      groups[formattedDate] = groups[formattedDate] || [];
      groups[formattedDate].push(activity);
      return groups;
    }, {} as Record<string, ActivityData[]>);
  };

  const [reqGetJob] = useGetJobsMutation();
  const [reqViewPlacement, resViewPlacement] =
    useViewJobPostPlacementMutation();

  const handleOpenPlacementForm = (logs: LogPlacement) => {
    reqGetJob({ id: logs?.job_id });

    reqViewPlacement(logs.id).then((res: any) => {
      setPlacementData(res.data.data);
      onOpenPlacement();
    });
  };

  // SETTING UP INITIAL LOGS
  useEffect(() => {
    if (resLogs.isSuccess) {
      const logs = resLogs.data.data.data;
      if (logs) {
        setActivities(groupActivitiesByDate(logs));
        setOrigActivities(logs);
      }
    }
  }, [resLogs.isSuccess]);

  // LOGIC FOR CHANGING FILTERS
  useEffect(() => {
    if (checkedFilters) {
      let allowedTypes = [] as any;
      if (checkedFilters.includes('0')) {
        setActivities(groupActivitiesByDate(origActivities));
      } else if (checkedFilters.length == 0) {
        setActivities(groupActivitiesByDate(origActivities));
      } else {
        checkedFilters.map((filter: string) => {
          if (Array.isArray(ActivityFilterDict[filter])) {
            allowedTypes.push(...ActivityFilterDict[filter]);
          }
        });
        const newActivities = origActivities.filter(
          (activity: ActivityData) => {
            const formattedType = activity.type?.toLowerCase();
            const formattedAction = activity.action
              ?.toLowerCase()
              ?.replaceAll(' ', '_');
            const type = ActivityTypeDict?.[formattedType]?.[formattedAction];
            return allowedTypes.includes(type);
          }
        );
        setActivities(groupActivitiesByDate(newActivities));
      }
    }
  }, [checkedFilters]);

  // clean up function when closing
  useEffect(() => {
    return () => {
      setActivities({});
      setOrigActivities([]);
    };
  }, []);

  return {
    filterList,
    checkedFilters,
    activities,
    resLogs,
    placementData,
    isOpenPlacement,
    handleOpenPlacementForm,
    onClosePlacement,
    handleActivityType,
    handleCheckFilter,
    handleIcons,
    handleCallback,
  };
};
