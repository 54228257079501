import { useMemo } from 'react';
import {
  useFetchTemplateDefaultQuery,
  useFetchTemplatesQuery,
} from 'services/templates/templates.query';

interface UseTemplateProps {
  groupId: number;
}

export const useTemplate = ({ groupId = 1 }: UseTemplateProps) => {
  const {
    data: templates,
    isFetching: isTemplatesFetching,
    isSuccess: isTemplatesSuccess,
    refetch: refetchTemplates,
  } = useFetchTemplatesQuery(
    { id: groupId },
    {
      selectFromResult: ({ data, ...other }) => ({
        ...other,
        data: data?.map((template) => ({
          ...template,
          value: template.id,
          label: template.title,
        })),
      }),
    }
  );
  const {
    data: defaultTemplates,
    isFetching: isDefaultTemplateFetching,
    isSuccess: isDefaultTemplateSuccess,
  } = useFetchTemplateDefaultQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => ({
        ...other,
        data: data?.map((template) => ({
          ...template,
          value: template.id,
          label: template.title,
        })),
      }),
    }
  );
  const defaultTemplate = useMemo(() => {
    return (
      defaultTemplates?.find((template) => template.group_id === groupId) ?? {
        id: 0,
        group_id: 0,
        title: '',
        subject: '',
        body: '',
        attachments: [],
      }
    );
  }, [isDefaultTemplateFetching, groupId]);

  return {
    templates,
    defaultTemplate,
    defaultTemplates,
    isTemplatesFetching,
    isDefaultTemplateFetching,
    isTemplatesSuccess,
    isDefaultTemplateSuccess,
    refetchTemplates,
  };
};

export default useTemplate;
