// Validation tags used in redux toolkit query

export const CANDIDATES = [
  'Candidates-List',
  'Candidates-By-Id',
  'Candidates-Pinned-Filter',
  'Candidates-Filter-List',
  'Candidates-Group-List',
];
export const LEADS = [
  'Leads',
  'Leads-Count',
  'Leads-Details',
  'Existing-Client-Prospects',
  'Leads-Types',
  'Lead-Status',
  'Lead-Types',
  'Leads-Client',
  'Leads-as-Contact',
  'Leads-Job',
  'Leads-Submits',
  'Leads-Kanban',
];
export const COMMON = [
  'Countries',
  'Lead-Sources',
  'Job-Specifications',
  'Education',
  'Experience-level',
  'Employee-Type',
  'Compensation',
  'Work-Type',
  'Skills',
  'Geo-Location',
  'Wild-Card-Search',
  'Default-Pitches',
  'Placeholder-Pair',
  'Tags',
  'Tags-Search',
  'All-Constants',
  'Candidate-Status',
];
export const USER = ['User'];
export const CLIENT = [
  'My-Client',
  'Client-Locations',
  'Client-Search',
  'Client-Contacts',
];
export const TEMPLATES = ['Templates', 'Default-templates'];
export const ENGAGES = ['Email-connections'];
export const JOBS = ['Job-Detail'];

export const ALL_TAGS = [].concat(
  COMMON,
  CANDIDATES,
  LEADS,
  USER,
  CLIENT,
  JOBS,
  ENGAGES,
  TEMPLATES
);
