import { Input, InputGroup } from '@chakra-ui/react';
import AtsAsyncSelect2 from 'components/app/AtsAsyncSelect2';
import AtsDatePicker from 'components/app/AtsDatePicker';
import AtsSelect from 'components/app/AtsSelect';
import { Field, FormikErrors } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFetchAllConstantsQuery } from 'services/common/constants.query';
import { useFetchUsersQuery } from 'services/user/users.query';
import { useAllListClientsMutation } from 'store/client.slice';
import { useContactListMutation } from 'store/contact.slice';
import { useJobTitleSearchMutation } from 'store/jobs.slice';
import { useSkillListMutation } from 'store/skills.slice';
import { useTagListingMutation } from 'store/tag.slice';

interface optionInterface {
  label: string;
  value: any;
}

interface queryInputInt {
  criteria: string;
  value: any; //can be default value for select2
  handleChange?: (e: any) => void;
  disabled?: boolean;
  name: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<any>>;
  isRange?: boolean;
}

const QueryInputs = ({
  criteria,
  value,
  handleChange,
  disabled,
  name,
  setFieldValue,
  isRange = false,
}: queryInputInt) => {
  const { skills, skillPageBtn } = useSelector((state: any) => state.skills);
  const { tags, tagsPageBtn } = useSelector((state: any) => state.tags);
  const { jobs } = useSelector((state: any) => state.jobs);
  const { contactList } = useSelector((state: any) => state.contacts);
  const { allClient } = useSelector((state: any) => state.clients);
  const { data: userList } = useFetchUsersQuery({});

  const yesNo: any = [
    {
      label: 'Yes',
      value: 'Yes',
    },
    {
      label: 'No',
      value: 'No',
    },
  ];

  const activeNot: any = [
    {
      label: 'Active',
      value: 'Active',
    },
    {
      label: 'Inactive',
      value: 'Inactive',
    },
  ];
  const { data: allContants } = useFetchAllConstantsQuery({});

  const [startDate, setStartDate] = useState(new Date());
  const [reqTags] = useTagListingMutation();
  const [reqSkills] = useSkillListMutation();
  const [reJobs] = useJobTitleSearchMutation();
  const [reqContacts] = useContactListMutation();
  const [reqClients] = useAllListClientsMutation();
  const [optionDef, setOptionDef] = useState([]);
  const [startRange, setStartRange] = useState(0);
  const [endRange, setEndRange] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const [isMulti, setIsMulti] = useState(true);
  const [startDateRange, endDateRange] = dateRange;

  useEffect(() => {
    if (criteria === 'Tags' || criteria === 'Skills') {
      if (criteria === 'Tags') {
        select2Change(value);
        setOptionDef(formatTagOption(tags));
      } else {
        select2Change(value);
        setOptionDef(formatSkillOption(skills));
      }
    }

    if (criteria === 'Work Status') {
      setIsMulti(true);
      select2Change(value);
      setOptionDef(formatWorkStatus(allContants?.candidate_work_status ?? []));
    }
    if (criteria === 'Years of Experience') {
      const range = value?.split(',');
      setStartRange(range[0]);
      setEndRange(range[1]);
    }
    if (criteria === 'Source') {
      select2Change(value);
      setOptionDef(formatCandidateSource(allContants?.candidate_source ?? []));
    }

    if (
      criteria === 'Resume Updated On' ||
      criteria === 'Created On' ||
      criteria === 'Last Emailed' ||
      criteria === 'Last Activity Date'
    ) {
      try {
        let date: any = moment(value).format();
        const valid = Date.parse(date);
        let formatdate: any = new Date(date);
        if (isNaN(valid)) {
          formatdate = new Date();
        }
        // setStartDate(formatdate);
        // setFieldValue(name, formatdate);
        calendarChange(formatdate);
      } catch (e) {
        console.log('date', e);
      }
    }
    if (
      criteria === 'Resume Available' ||
      criteria === 'DNC' ||
      criteria === 'Added to Campaign'
    ) {
      setIsMulti(false);
      setOptionDef(yesNo);
    }
    if (criteria === 'Campaign Status') {
      setOptionDef(activeNot);
      setIsMulti(false);
      select2Change(value);
    }

    if (
      criteria === 'Created by' ||
      criteria === 'Primary Recruiter' ||
      criteria === 'Account Manager'
    ) {
      userOptions();
      setIsMulti(true);
      select2Change(value);
    }
    if (criteria === 'Work type') {
      let options: any = [];
      if (allContants?.work_types?.length) {
        allContants?.work_types?.forEach((item) => {
          options.push({
            label: item.name,
            value: item.id,
          });
        });
      }
      setOptionDef(options);
      setIsMulti(true);
      select2Change(value);
    }
    if (criteria === 'Employment Type') {
      let options: any = [];
      if (allContants?.employment_types?.length) {
        allContants?.employment_types?.forEach((item) => {
          options.push({
            label: item.name,
            value: item.id,
          });
        });
      }
      setOptionDef(options);
      setIsMulti(true);
      select2Change(value);
    }

    if (criteria === 'Job Title') {
      setOptionDef(formatJobOption(jobs));
      setIsMulti(true);
      select2Change(value);
    }

    if (criteria === 'Contact') {
      setOptionDef(formatContactOption(contactList));
      setIsMulti(true);
      select2Change(value);
    }
    if (criteria === 'Client') {
      setOptionDef(formatClientOption(allClient));
      setIsMulti(true);
      select2Change(value);
    }

    if (criteria === 'Client Type' || criteria === 'Contact Type') {
      formatClientContactTypes();
      select2Change(value);
      setIsMulti(true);
    }
    if (criteria === 'Job Candidate Status') {
      formatCandidateStatus();
      select2Change(value);
      setIsMulti(true);
    }
    if (criteria === 'Job Status') {
      formatJobStatus();
      select2Change(value);
      setIsMulti(true);
    }
  }, [criteria]);
  const userOptions = () => {
    let option: any = [];
    if (userList && Object.values(userList).length > 0) {
      Object.values(userList).map((item: any) => {
        option.push({
          label: [item?.first_name, item?.last_name].join(' '),
          value: item?.id,
        });
      });
    }
    setOptionDef(option);
  };

  useEffect(() => {
    if (isRange) {
      const date = value.split(',');
      if (date.length > 1) {
        const update = [new Date(date[0]), new Date(date[1])];
        setDateRange(update);
      }
    }
  }, [isRange]);

  useEffect(() => {
    if (criteria === 'Years of Experience') {
      const value = [startRange, endRange].join(',');
      setFieldValue(name, value);
    }
  }, [endRange, startRange]);

  const formatJobStatus = () => {
    // job_status;
    let groups: any = [];
    if (allContants?.job_status?.length) {
      allContants?.job_status?.forEach((item: any) => {
        groups.push({
          label: item.status,
          value: item.id,
        });
      });
    }

    setOptionDef(groups);
  };

  const formatCandidateStatus = () => {
    let groups: any = [];
    if (allContants?.candidate_status?.length) {
      allContants?.candidate_status?.forEach((item: any) => {
        if (item?.sub?.length > 0) {
          let option: any = [];
          item?.sub?.forEach?.((sub: any) => {
            option.push({
              label: sub.sub_status,
              value: sub.id,
            });
          });

          groups.push({
            label: item.status,
            options: option,
          });
        }
      });
    }
    setOptionDef(groups);
  };

  const formatClientContactTypes = () => {
    let option: any = [];
    let data: any =
      criteria === 'Client Type'
        ? allContants?.client_types ?? []
        : allContants?.contact_types ?? [];

    if (data.length) {
      data.forEach((item: any) => {
        option.push({
          label: item.label,
          value: item.id,
        });
      });
    }

    setOptionDef(option);
  };

  const formatTagOption = (tags: any) => {
    let options: any = [];

    if (tags?.length > 0) {
      tags?.map(async (tag: any) => {
        options.push({
          label: tag.tag_name,
          value: tag.id,
        });
      });
    }

    return options;
  };

  const promiseTagOptions = (
    inputValue: string,
    callback: (options: optionInterface[]) => void
  ) => {
    let newParam = {
      page: tagsPageBtn.page,
      take: tagsPageBtn.limit,
      query: inputValue,
    };
    setFieldValue(name, inputValue);
    reqTags(newParam).then((result: any) => {
      if (result?.data?.data?.data) {
        const getData = async () => {
          const option = formatTagOption(result?.data?.data?.data);
          callback(option);
        };
        getData().catch(console.error);
      }
    });
  };

  const formatWorkStatus = (status: any) => {
    let options: any = [];

    if (status?.length > 0) {
      status?.map(async (stat: any) => {
        options.push({
          label: stat.status,
          value: stat.id,
        });
      });
    }

    return options;
  };

  const formatCandidateSource = (source: any) => {
    let options: any = [];

    if (source?.length > 0) {
      source?.forEach(async (source: any) => {
        options.push({
          label: source.source,
          value: source.id,
          sub: source.sub,
        });
      });
    }

    return options;
  };

  const formatSkillOption = (skills: any) => {
    let options: any = [];

    if (skills?.length > 0) {
      skills?.map(async (skill: any) => {
        options.push({
          label: skill.skill,
          value: skill.id,
        });
      });
    }

    return options;
  };

  const promiseSkillOptions = (
    inputValue: string,
    callback: (options: optionInterface[]) => void
  ) => {
    let newParam = {
      page: skillPageBtn.page,
      take: skillPageBtn.limit,
      query: inputValue,
    };

    reqSkills(newParam).then((result: any) => {
      if (result?.data?.data?.data) {
        const getData = async () => {
          const option = formatSkillOption(result?.data?.data?.data);
          callback(option);
        };
        getData().catch(console.error);
      }
    });
  };

  const formatJobOption = (jobs: any) => {
    let options: any = [];
    if (jobs.length > 0) {
      jobs?.map(async (job: any) => {
        options.push({
          label: job.title,
          value: job.id,
        });
      });
    }

    return options;
  };

  const promiseJobOptions = (
    inputValue: string,
    callback: (options: optionInterface[]) => void
  ) => {
    let newParam = {
      query: inputValue,
    };

    reJobs(newParam).then((result: any) => {
      const getData = async () => {
        const option = formatJobOption(result?.data?.data);
        callback(option);
      };
      getData().catch(console.error);
    });
  };

  const formatClientOption = (client: any) => {
    let options: any = [];
    if (client.length > 0) {
      client.map((item: any) => {
        options.push({
          value: item?.id,
          label: item?.name,
        });
      });
    }

    return options;
  };

  const promiseClientOptions = (
    inputValue: string,
    callback: (options: optionInterface[]) => void
  ) => {
    let newParam = {
      query: inputValue,
    };

    reqClients({ data: newParam }).then((result: any) => {
      const getData = async () => {
        const option = formatClientOption(result?.data?.data?.data);
        callback(option);
      };
      getData().catch(console.error);
    });
  };

  const formatContactOption = (contacts: any) => {
    let options: any = [];
    if (contacts.length > 0) {
      contacts.map((item: any) => {
        options.push({
          value: item?.id,
          label: [item?.first_name, item?.last_name].join(' '),
        });
      });
    }

    return options;
  };

  const promiseContactOptions = (
    inputValue: string,
    callback: (options: optionInterface[]) => void
  ) => {
    let newParam = {
      query: inputValue,
    };

    reqContacts(newParam).then((result: any) => {
      const getData = async () => {
        const option = formatContactOption(result?.data?.data?.data);
        callback(option);
      };
      getData().catch(console.error);
    });
  };

  const select2Change = async (value: any) => {
    let options: any = [];
    try {
      if (value?.length > 0) {
        value?.map((item: any) => {
          options.push(item.value);
        });
      }
      setFieldValue(name, options.join(','));
    } catch (e) {}
  };

  const calendarChange = (date: any) => {
    // console.log('name', name);
    setFieldValue(name, moment(date).format('YYYY-MM-DD'));
    setStartDate(date);
  };

  let input = (
    <Field
      as={Input}
      id={name}
      name={name}
      type="text"
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
  );
  if (criteria === 'Tags') {
    input = (
      <AtsAsyncSelect2
        loadOptions={promiseTagOptions}
        isMulti={true}
        defaultOptions={optionDef}
        defaultValue={value}
        onChange={select2Change}
        cacheOptions={false}
      />
    );
  }
  if (criteria === 'Skills') {
    input = (
      <AtsAsyncSelect2
        loadOptions={promiseSkillOptions}
        isMulti={true}
        defaultOptions={optionDef}
        defaultValue={value}
        onChange={select2Change}
        cacheOptions={false}
      />
    );
  }

  if (criteria === 'Job Title') {
    input = (
      <AtsAsyncSelect2
        loadOptions={promiseJobOptions}
        isMulti={true}
        defaultOptions={optionDef}
        defaultValue={value}
        onChange={select2Change}
        cacheOptions={false}
      />
    );
  }

  if (criteria === 'Contact') {
    input = (
      <AtsAsyncSelect2
        loadOptions={promiseContactOptions}
        isMulti={true}
        defaultOptions={optionDef}
        defaultValue={value}
        onChange={select2Change}
        cacheOptions={false}
      />
    );
  }

  if (criteria === 'Client') {
    input = (
      <AtsAsyncSelect2
        loadOptions={promiseClientOptions}
        isMulti={true}
        defaultOptions={optionDef}
        defaultValue={value}
        onChange={select2Change}
        cacheOptions={false}
      />
    );
  }

  if (
    criteria === 'Work Status' ||
    criteria === 'Resume Available' ||
    criteria === 'DNC' ||
    criteria === 'Campaign Status' ||
    criteria === 'Added to Campaign' ||
    criteria === 'Created by' ||
    criteria === 'Primary Recruiter' ||
    criteria === 'Account Manager' ||
    criteria === 'Employment Type' ||
    criteria === 'Work type' ||
    criteria === 'Source' ||
    criteria === 'Client Type' ||
    criteria === 'Contact Type' ||
    criteria === 'Job Candidate Status' ||
    criteria === 'Job Status'
  ) {
    input = (
      <AtsSelect
        options={optionDef}
        defaultValue={value}
        onChange={select2Change}
        isMulti={isMulti}
      />
    );
  }
  if (criteria === 'Years of Experience') {
    input = (
      <InputGroup gap="2px">
        <Field
          as={Input}
          textAlign="center"
          type="number"
          placeholder="0"
          value={startRange}
          onChange={(e: any) => setStartRange(e.target.value)}
        />
        <Field
          as={Input}
          textAlign="center"
          type="number"
          placeholder="0"
          value={endRange}
          onChange={(e: any) => setEndRange(e.target.value)}
        />
      </InputGroup>
    );
  }

  if (
    criteria === 'Resume Updated On' ||
    criteria === 'Created On' ||
    criteria === 'Last Emailed' ||
    criteria === 'Last Activity Date'
  ) {
    if (isRange) {
      input = (
        <AtsDatePicker
          selected={startDateRange}
          startDate={startDateRange}
          endDate={endDateRange}
          isClearable={true}
          selectsRange={true}
          onChange={(date: any) => {
            setDateRange(date);
            let dateString: any = '';
            if (date[1] && date[0]) {
              dateString = [
                moment.utc(date[0]).format('YYYY-MM-DD'),
                moment.utc(date[1]).format('YYYY-MM-DD'),
              ].join(',');
            }
            setFieldValue(name, dateString);
          }}
        />
      );
    } else {
      input = (
        <AtsDatePicker
          selected={startDate}
          selectsRange={false}
          onChange={(date: any) => {
            calendarChange(date);
          }}
        />
      );
    }
  }

  return input;
};

export default QueryInputs;
