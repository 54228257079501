import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import * as pdfjsLib from 'pdfjs-dist';
// import pdfWorker  from './pdf.worker.js';
import pdfLib from 'pdf-lib';

import { PDFDocument, PDFPage, RGB, rgb, StandardFonts } from 'pdf-lib';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';

import axios from 'axios';
import { BASE_URL } from 'constants/values';

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDropzone } from 'react-dropzone';
import { BsCloudUpload } from 'react-icons/bs';
import randomstring from 'utils/randomstring';
import { Box, useToast } from '@chakra-ui/react';

import watermarkUrl from 'assets/images/talently-watermark.png';
import LoadingPage from 'components/app/Loading';

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const PdfEditorComponent = forwardRef(
  (
    {
      selectedVersion,
      candidate,
      onClose,
      watermark,
      header,
    }: {
      selectedVersion: any;
      candidate: any;
      onClose: any;
      watermark: any;
      header: any;
    },
    ref
  ) => {
    const { hasSwitchedAccount } = useSelector((state: any) => state.app);

    const toast = useToast();

    const baseCanvasRefs = useRef([]);
    const overlayCanvasRefs = useRef([]);
    const [pdfDoc, setPdfDoc] = useState(null);
    const [annotations, setAnnotations] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const scale = 1.5;
    const pdfUrl = selectedVersion?.signedUrl;
    const [isRendering, setIsRendering] = useState(false); // Track rendering state
    const [isLoading, setIsLoading] = useState(false);

    useImperativeHandle(ref, () => ({
      changeAnnotationColor,
      downloadPdf,
    }));

    useEffect(() => {
      loadPdf();
    }, []);

    const loadPdf = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument(pdfUrl);
        const pdfDoc_ = await loadingTask.promise;
        setPdfDoc(pdfDoc_);
        renderAllPages(pdfDoc_);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    const renderAllPages = async (doc: PDFDocumentProxy) => {
      setIsRendering(true);
      try {
        const pages = [];
        for (let i = 1; i <= doc.numPages; i++) {
          const page = await doc.getPage(i);
          pages.push(page);
        }
        setIsRendering(false);
        pages.forEach((page, index) => renderPage(page, index));
      } catch (error) {
        console.error('Error rendering pages:', error);
        setIsRendering(false);
      }
    };

    const renderPage = async (page: any, index: number) => {
      try {
        const viewport = page.getViewport({ scale: 1, rotation: 0 }); // rotation: 0 if you want it upright
        baseCanvasRefs.current[index] =
          baseCanvasRefs.current[index] || document.createElement('canvas');
        overlayCanvasRefs.current[index] =
          overlayCanvasRefs.current[index] || document.createElement('canvas');

        const baseCanvas = baseCanvasRefs.current[index];
        const baseContext = baseCanvas.getContext('2d');
        baseCanvas.width = viewport.width;
        baseCanvas.height = viewport.height;

        const overlayCanvas = overlayCanvasRefs.current[index];
        overlayCanvas.width = viewport.width;
        overlayCanvas.height = viewport.height;

        await page.render({
          canvasContext: baseContext,
          viewport,
        }).promise;

        drawAnnotations(index + 1);
      } catch (error) {
        console.error('Error fetching page:', error);
      }
    };

    const drawAnnotations = (pageNum: number) => {
      const overlayCanvas = overlayCanvasRefs.current[pageNum - 1];
      const overlayContext = overlayCanvas.getContext('2d');
      if (!overlayContext) return;

      overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      overlayContext.fillStyle = 'rgba(255, 0, 0, 0.5)';

      annotations.forEach((annotation: any) => {
        if (annotation.page === pageNum) {
          overlayContext.fillRect(
            annotation.x * scale,
            annotation.y * scale,
            annotation.width * scale,
            annotation.height * scale
          );
        }
      });
    };

    const handleMouseDown = (event: any, pageIndex: number) => {
      const canvas = baseCanvasRefs.current[pageIndex];
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;

      setStartX(((event.clientX - rect.left) * scaleX) / scale);
      setStartY(((event.clientY - rect.top) * scaleY) / scale);
      setIsDrawing(true);
    };

    const handleMouseMove = (event: any, pageIndex: number) => {
      if (isDrawing) {
        const canvas = overlayCanvasRefs.current[pageIndex];
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        const endX = ((event.clientX - rect.left) * scaleX) / scale;
        const endY = ((event.clientY - rect.top) * scaleY) / scale;

        drawPreviewRect(startX, startY, endX, endY, pageIndex);
      }
    };

    const handleMouseUp = (event: any, pageIndex: number) => {
      if (isDrawing) {
        const canvas = overlayCanvasRefs.current[pageIndex];
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        const endX = ((event.clientX - rect.left) * scaleX) / scale;
        const endY = ((event.clientY - rect.top) * scaleY) / scale;

        addAnnotation(startX, startY, endX, endY, pageIndex + 1);
        setIsDrawing(false);
      }
    };

    const drawPreviewRect = (
      startX: number,
      startY: number,
      endX: number,
      endY: number,
      pageIndex: number
    ) => {
      const overlayCanvas = overlayCanvasRefs.current[pageIndex];
      const overlayContext = overlayCanvas.getContext('2d');
      if (!overlayContext) return;

      overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      drawAnnotations(pageIndex + 1);
      overlayContext.fillStyle = 'rgba(255, 0, 0, 0.5)';
      overlayContext.fillRect(
        startX * scale,
        startY * scale,
        (endX - startX) * scale,
        (endY - startY) * scale
      );
    };

    const addAnnotation = (
      startX: number,
      startY: number,
      endX: number,
      endY: number,
      page: any
    ) => {
      const width = Math.abs(endX - startX);
      const height = Math.abs(endY - startY);
      setAnnotations([
        ...annotations,
        {
          page, // Associate annotation with the current page
          x: Math.min(startX, endX),
          y: Math.min(startY, endY),
          width,
          height,
        },
      ]);
    };

    const fillRectangleWithRandomText = (
      page: PDFPage,
      x: number,
      y: number,
      width: number,
      height: number
    ) => {
      const fontSize = 14; // Base font size for the text
      const padding = 10; // Padding from the edges of the rectangle
      const maxWidth = width - padding * 2; // Usable width inside the rectangle
      const maxHeight = height - padding * 2; // Usable height inside the rectangle
      const generateRandomText = (length: number) => {
        const chars =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        return Array.from({ length }, () =>
          chars.charAt(Math.floor(Math.random() * chars.length))
        ).join('');
      };

      const maxTextWidth = Math.floor(maxWidth / (fontSize * 0.6)); // Maximum number of characters per row
      const totalTextCount = Math.floor(
        (maxWidth * maxHeight) / (fontSize * fontSize)
      ); // Total number of random texts to generate

      for (let i = 0; i < totalTextCount; i++) {
        const randomText = generateRandomText(
          Math.min(maxTextWidth, Math.random() * maxTextWidth)
        );

        // Generate random positions inside the rectangle, respecting padding
        const textX =
          x +
          padding +
          Math.random() * (maxWidth - randomText.length * fontSize * 0.6);
        const textY = y + padding + Math.random() * maxHeight;

        // Ensure text stays within the rectangle's boundaries
        if (
          textX + randomText.length * fontSize * 0.6 <= x + width - padding &&
          textY + fontSize <= y + height - padding
        ) {
          page.drawText(randomText, {
            x: textX,
            y: textY,
            size: fontSize,
            color: rgb(0, 0, 0), // Black text
          });
        }
      }
    };

    const downloaddPdf = async () => {
      setIsLoading(true);

      if (!pdfDoc) return;

      try {
        const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
        const pdfLibDoc = await PDFDocument.load(pdfBytes);

        const pages = pdfLibDoc.getPages();

        pages.forEach((page, index) => {
          const { width, height } = page.getSize();
          page.setSize(width, height + 15);

          // Highlight annotations (if any)
          annotations
            .filter((annotation) => annotation.page === index + 1)
            .forEach((annotation) => {
              // Adjust the position with scale and correct coordinate origin for PDF
              const adjustedX = annotation.x * scale;
              const adjustedY =
                height - annotation.y * scale - annotation.height * scale + 4;
              const adjustedWidth = annotation.width * scale + 2;
              const adjustedHeight = annotation.height * scale + 2;

              // Draw a white rectangle to obscure the content
              page.drawRectangle({
                x: adjustedX,
                y: adjustedY,
                width: adjustedWidth,
                height: adjustedHeight,
                color: rgb(1, 1, 1),
              });
            });
        });

        const pdfWithWatermarkBytes = await pdfLibDoc.save();
        const blob = new Blob([pdfWithWatermarkBytes], {
          type: 'application/pdf',
        });

        const pdfArray = new Uint8Array(await blob.arrayBuffer());
        const loadingTask = pdfjsLib.getDocument({ data: pdfArray });
        const pdf = await loadingTask.promise;

        const newPdfDoc = await PDFDocument.create();

        for (let i = 0; i < pdf.numPages; i++) {
          const page = await pdf.getPage(i + 1);
          const viewport = page.getViewport({ scale: 2 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          await page.render({ canvasContext: context, viewport }).promise;

          const imgData = canvas.toDataURL('image/png');
          const img = await newPdfDoc.embedPng(imgData);
          const pdfPage = newPdfDoc.addPage([
            viewport.width / 2,
            viewport.height / 2,
          ]); // Adjust page size to original dimensions
          pdfPage.drawImage(img, {
            x: 0,
            y: 0,
            width: viewport.width / 2,
            height: viewport.height / 2,
          });
        }

        const newPdfBytes = await newPdfDoc.save();
        const newBase64Pdf = btoa(
          String.fromCharCode(...new Uint8Array(newPdfBytes))
        );
        const newBlob = new Blob([newPdfBytes], { type: 'application/pdf' });

        console.log(blob);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(newBlob);
        link.download = 'watermarked.pdf';
        link.click();
        setIsLoading(false);
      } catch (error) {
        console.error('Error downloading PDF:', error);
      }
    };

    // working code
    const downloadPdf = async () => {
      setIsLoading(true);

      if (!pdfDoc) return;

      try {
        const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
        const pdfLibDoc = await PDFDocument.load(pdfBytes);

        const watermarkImage = await loadWatermarkImage(pdfLibDoc);
        if (!watermarkImage) {
          console.error('Failed to load watermark image.');
          return;
        }

        const pages = pdfLibDoc.getPages();
        const watermarkOpacity = 0.1; // For fading the watermark

        pages.forEach((page, index) => {
          const { width, height } = page.getSize();
          page.setSize(width, height + 25);

          const pageHeight = page.getHeight();

          // Highlight annotations (if any)
          annotations
            .filter((annotation) => annotation.page === index + 1)
            .forEach((annotation) => {
              // Adjust the position with scale and correct coordinate origin for PDF
              const adjustedX = annotation.x * scale;
              const adjustedY =
                height - annotation.y * scale - annotation.height * scale + 5; // Added a small offset (+5)
              const adjustedWidth = annotation.width * scale;
              const adjustedHeight = annotation.height * scale;

              fillRectangleWithRandomText(
                page,
                adjustedX,
                adjustedY,
                adjustedWidth,
                adjustedHeight
              );

              // Draw a white rectangle to obscure the content
              page.drawRectangle({
                x: adjustedX,
                y: adjustedY,
                width: adjustedWidth,
                height: adjustedHeight,
                color: rgb(1, 1, 1),
                opacity: 1,
              });
            });

          if (header) {
            // Add header with watermark at the top
            const headerWidth = width * 0.1;
            const headerHeight =
              watermarkImage.height * (headerWidth / watermarkImage.width);

            page.drawImage(watermarkImage, {
              x: width - headerWidth - 10,
              y: height - headerHeight + 25,
              width: headerWidth,
              height: headerHeight,
              opacity: 1,
            });
          }

          const watermarkWidth = width * 0.5; // Scale watermark width to 50% of page width
          const watermarkHeight =
            watermarkImage.height * (watermarkWidth / watermarkImage.width);

          if (watermark) {
            // Draw watermark at the center of the page
            page.drawImage(watermarkImage, {
              x: (width - watermarkWidth) / 2,
              y: (height - watermarkHeight) / 2,
              width: watermarkWidth,
              height: watermarkHeight,
              opacity: watermarkOpacity,
            });
          }
        });

        const pdfWithWatermarkBytes = await pdfLibDoc.save();
        console.log(pdfWithWatermarkBytes, 'pdfWithWatermarkBytes');
        const blob = new Blob([pdfWithWatermarkBytes], {
          type: 'application/pdf',
        });

        const pdfArray = new Uint8Array(await blob.arrayBuffer());
        const loadingTask = pdfjsLib.getDocument({ data: pdfArray });
        const pdf = await loadingTask.promise;

        const newPdfDoc = await PDFDocument.create();

        for (let i = 0; i < pdf.numPages; i++) {
          const page = await pdf.getPage(i + 1);
          const viewport = page.getViewport({ scale: 2 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          await page.render({ canvasContext: context, viewport }).promise;

          const imgData = canvas.toDataURL('image/png');
          const img = await newPdfDoc.embedPng(imgData);
          const pdfPage = newPdfDoc.addPage([
            viewport.width / 2,
            viewport.height / 2,
          ]); // Adjust page size to original dimensions
          pdfPage.drawImage(img, {
            x: 0,
            y: 0,
            width: viewport.width / 2,
            height: viewport.height / 2,
          });

          const { width, height } = pdfPage.getSize();
          // Add header with watermark at the top
          const headerWidth = width * 0.1;
          const headerHeight =
            watermarkImage.height * (headerWidth / watermarkImage.width);

          // pdfPage.drawRectangle({
          //     x: 0, // Slightly expand beyond the header dimensions
          //     y: height - headerHeight - 8,
          //     width: width,  // Adjust width to provide padding
          //     height: 400, // Adjust height to provide padding
          //     color: rgb(1, 1, 1), // White color
          //     opacity: 1,
          // });

          // Add red and black text below the header
          const fontSize = 8;

          const userDetails =
            hasSwitchedAccount !== null
              ? hasSwitchedAccount
              : JSON.parse(localStorage.auth);

          // const userName = `${userDetails.first_name} ${userDetails.last_name}  ${userDetails.designation ? "| " + userDetails.designation : '' }`
          const userName = `Presented By: ${userDetails.first_name} ${userDetails.last_name}`;

          const userContact = `${userDetails.email} ${
            userDetails.phone_number ? '| ' + userDetails.phone_number : ''
          }`;

          const red = 105 / 255;
          const green = 48 / 255;
          const blue = 202 / 255;

          pdfPage.drawText(userName, {
            x: 10,
            y: height - headerHeight + 6,
            size: fontSize,
            color: rgb(red, green, blue),
          });

          pdfPage.drawText(userContact, {
            x: 10,
            y: height - headerHeight - 4,
            size: fontSize,
            color: rgb(0, 0, 0), // Black color
          });
        }

        const newPdfBytes = await newPdfDoc.save();
        // const newBase64Pdf = btoa(String.fromCharCode(...new Uint8Array(newPdfBytes)));
        const newBlob = new Blob([newPdfBytes], { type: 'application/pdf' });

        // console.log(blob)
        // const link = document.createElement('a');
        // link.href = URL.createObjectURL(newBlob);
        // link.download = 'watermarked.pdf';
        // link.click();
        // setIsLoading(false);

        // console.log(candidate, "candidate")
        const candidate_id = candidate.id;
        let url = BASE_URL + `/resume-parser/candidate-file/${candidate_id}`;

        let id = randomstring();

        const config = {
          withCredentials: true,
        };
        let formData = new FormData();
        const fileName = 'Talently Candidate.pdf';
        formData.append('file', newBlob, fileName);
        formData.append('sub_file_type', 'redacted');

        await axios
          .post(url, formData, config)
          .then((res) => {
            const data = {
              ...res.data?.data,
              local_id: id,
            };

            toast({
              title: 'Redacted Resume Saved',
              status: 'success',
              isClosable: true,
              duration: 3000,
              position: 'top',
            });

            setIsLoading(false);
            onClose();
          })
          .catch((err) => {
            console.log('err', err);
          });
      } catch (error) {
        console.error('Error downloading PDF:', error);
      }
    };

    // const downloadPdf = async () => {
    //     if (!pdfDoc) return;

    //     try {
    //         const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
    //         const pdfLibDoc = await PDFDocument.load(pdfBytes);

    //         const watermarkImage = await loadWatermarkImage(pdfLibDoc);
    //         if (!watermarkImage) {
    //             console.error('Failed to load watermark image.');
    //             return;
    //         }

    //         const pages = pdfLibDoc.getPages();
    //         const watermarkOpacity = 0.1; // For fading the watermark
    //         const additionalTopMargin = 100; // Space to reserve at the top of the page

    //         for (const page of pages) {
    //             const { width, height } = page.getSize();

    //             // Create a new page with the same size
    //             const newPage = pdfLibDoc.addPage([width, height + additionalTopMargin]);

    //             // Draw the original page content, but shifted downwards to make space for new header
    //             newPage.drawPage(page, {
    //                 x: 0,
    //                 y: -additionalTopMargin,
    //                 width: width,
    //                 height: height,
    //             });

    //             // Add watermark to the center of the new page
    //             const watermarkWidth = width * 0.5; // Scale watermark width to 50% of page width
    //             const watermarkHeight = watermarkImage.height * (watermarkWidth / watermarkImage.width);
    //             newPage.drawImage(watermarkImage, {
    //                 x: (width - watermarkWidth) / 2,
    //                 y: (height - watermarkHeight) / 2 + additionalTopMargin,
    //                 width: watermarkWidth,
    //                 height: watermarkHeight,
    //                 opacity: watermarkOpacity,
    //             });

    //             // Add header watermark at the top of the new page
    //             const headerWidth = width * 0.1;
    //             const headerHeight = watermarkImage.height * (headerWidth / watermarkImage.width);
    //             newPage.drawImage(watermarkImage, {
    //                 x: (width - headerWidth) / 2,
    //                 y: height + additionalTopMargin - headerHeight - 20,
    //                 width: headerWidth,
    //                 height: headerHeight,
    //                 opacity: 1,
    //             });

    //             // Add red and black text below the header within the reserved margin space
    //             const fontSize = 12;
    //             let headerTextStartY = height + additionalTopMargin - headerHeight - 50;

    //             newPage.drawText('Talent. Utkarsh', {
    //                 x: 20,
    //                 y: headerTextStartY,
    //                 size: fontSize,
    //                 color: rgb(1, 0, 0), // Red color
    //             });

    //             headerTextStartY -= 20; // Decrease Y for the next line of text
    //             newPage.drawText('Utkarsh Jaiswal | Software Engineer', {
    //                 x: 20,
    //                 y: headerTextStartY,
    //                 size: fontSize,
    //                 color: rgb(1, 0, 0), // Red color
    //             });

    //             headerTextStartY -= 20; // Decrease Y for the next line of text
    //             newPage.drawText('+9188889998889 | utk@djde.com', {
    //                 x: 20,
    //                 y: headerTextStartY,
    //                 size: fontSize,
    //                 color: rgb(0, 0, 0), // Black color
    //             });

    //             headerTextStartY -= 20; // Decrease Y for the next line of text
    //             newPage.drawText('www.de.com', {
    //                 x: 20,
    //                 y: headerTextStartY,
    //                 size: fontSize,
    //                 color: rgb(0, 0, 0), // Black color
    //             });

    //             // Remove the original page after copying its content
    //             pdfLibDoc.removePage(pdfLibDoc.getPageIndices(page));
    //         }

    //         const pdfWithWatermarkBytes = await pdfLibDoc.save();
    //         const blob = new Blob([pdfWithWatermarkBytes], { type: 'application/pdf' });
    //         const link = document.createElement('a');
    //         link.href = URL.createObjectURL(blob);
    //         link.download = 'watermarked.pdf';
    //         link.click();
    //     } catch (error) {
    //         console.error('Error downloading PDF:', error);
    //     }
    // };

    const loadWatermarkImage = async (pdfLibDoc: PDFDocument) => {
      try {
        const response = await fetch(watermarkUrl);
        const imageBytes = await response.arrayBuffer();
        const watermarkImage = await pdfLibDoc.embedPng(imageBytes);
        return watermarkImage;
      } catch (error) {
        console.error('Error loading watermark image:', error);
        return null;
      }
    };

    const changeAnnotationColor = () => {
      overlayCanvasRefs.current.forEach((overlayCanvas) => {
        const overlayContext = overlayCanvas.getContext('2d');
        if (!overlayContext) return;

        overlayContext.clearRect(
          0,
          0,
          overlayCanvas.width,
          overlayCanvas.height
        );
        overlayContext.fillStyle = 'rgba(255, 255, 255, 1)';

        annotations.forEach((annotation) => {
          if (
            annotation.page ===
            overlayCanvasRefs.current.indexOf(overlayCanvas) + 1
          ) {
            overlayContext.fillRect(
              annotation.x * scale,
              annotation.y * scale,
              annotation.width * scale,
              annotation.height * scale
            );
          }
        });
      });

      toast({
        title: 'Redacted Succesful',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top',
      });
    };

    if (isLoading) return <LoadingPage />;

    return (
      <div
        className="pdf-viewer"
        style={{
          position: 'relative',
          display: 'block',
          overflowY: 'auto',
          height: '80vh',
        }}
      >
        {pdfDoc &&
          Array.from({ length: pdfDoc.numPages }, (_, index) => (
            <div
              key={index}
              style={{
                position: 'relative',
                marginBottom: '50px',
                width: '100%',
                minWidth: '400px',
                maxWidth: '800px',
              }}
            >
              <canvas
                style={{ width: '100%' }}
                ref={(el) => (baseCanvasRefs.current[index] = el)}
                onMouseDown={(event) => handleMouseDown(event, index)}
                onMouseMove={(event) => handleMouseMove(event, index)}
                onMouseUp={(event) => handleMouseUp(event, index)}
              />
              <canvas
                ref={(el) => (overlayCanvasRefs.current[index] = el)}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  pointerEvents: 'none',
                  width: '100%',
                }}
              ></canvas>
            </div>
          ))}

        {/* <button onClick={downloadPdf}>Redact PDF</button>
            <button onClick={changeAnnotationColor}>Change Annotations to White</button> */}
      </div>
    );
  }
);

export default PdfEditorComponent;
