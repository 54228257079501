import { apiSlice } from 'store/api.slice';
import { DefaultTemplateList, TemplateList } from './templates.type';
import { ApiResponse } from 'services/type';

export const templatesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchTemplates: builder.query<Array<TemplateList>, { id: number }>({
      query: ({ id }) => ({
        url: `/email-template/raw-list/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['Templates'],
      transformResponse: (result: ApiResponse<TemplateList[]>) => {
        return result.success ? result.data : [];
      },
    }),
    fetchTemplateDefault: builder.query<Array<DefaultTemplateList>, {}>({
      query: () => ({
        url: `/email-template/default-list`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      providesTags: ['Default-templates'],
      transformResponse: (result: ApiResponse<DefaultTemplateList[]>) => {
        return result.success ? result.data : [];
      },
    }),
  }),
});

export const { useFetchTemplatesQuery, useFetchTemplateDefaultQuery } =
  templatesApi;
