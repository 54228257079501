/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import '../../../../assets/css/autocomplete.css';

import { useGetJobsMutation } from 'store/jobs.slice';
import {
  useGetCandidatetatusMutation,
  useGetSubStatusMutation,
} from 'store/constant.slice';
import {
  getCandidate,
  setloadingCandidate,
  useChangeSubStatusMutation,
  useGetCandidatesMutation,
  useListCandidatesMutation,
  useUnRejectCandidateMutation,
} from 'store/candidates.slice';

import Button from 'Library/Button';
import CandidateJobList from './CandidateJobList';
import CandidatesInterviewModal from '../Drawer/Interviews/modal';
import RejectModal from 'modules/Jobs/Layout/ViewCandidatesModule/Modal/Reject';
import SubmitToClientModal from 'modules/Jobs/Layout/ViewCandidatesModule/Modal/SubmitToClient';
import { CandidatesTypes, swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';
import LoadingPage from 'components/app/Loading';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import {
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import HiredModal from 'modules/Jobs/Layout/ViewCandidatesModule/Modal/HiredModal';
import {
  setClientAgreement,
  useListClientAgreementMutation,
} from 'store/clientagreement.slice';
import { useTRservices } from '../Table/useTRservices';
import delay from 'utils/delay';
import { useLocation } from 'react-router';
import {
  useFetchCandidateStatusQuery,
  useLazyFetchPlaceholderPairQuery,
} from 'services/common/constants.query';
import useGetCandidate from '../Drawer/hooks/useGetCandidate';
import { useFetchJobByIdQuery } from 'services/jobs/job.query';
import RenderIf from 'components/RenderIf';
import useToast from 'hooks/useToast';
import {
  useChangeCandidateSubStatusMutation,
  useRejectCandidateMutation,
  useUnrejectCandidateMutation,
} from 'services/candidates/candidate.mutation';
import { useLazyFetchClientAgreementsQuery } from 'services/client/client.query';
import { apiSlice } from 'store/api.slice';

//Allowed status to procceed
// SCREEN | BLASTED
const ALLOWED_SUBMIT_TO_CLIENT_STATUS = [2, 8];
// SUBMITS  | SCREEN | INTERVIEW | BLASTED
const ALLOWED_SCHEDULE_INTERVIEW_STATUS = [1, 2, 3, 8];
// SUBMITS | SCREEN | INTERVIEW |  OFFERED | HIRED | BLASTED
const ALLOWED_REJECT_STATUS = [1, 2, 3, 4, 5, 8];
//REJECTED
const ALLOWED_UNREJECT_STATUS = 6;

interface ActionButtonprops {
  onClose: () => void;
  handleNextData: () => void;
  candidateId: number;
  jobId: number;
}
const ActionButton = ({
  onClose,
  handleNextData,
  candidateId,
  jobId,
}: ActionButtonprops) => {
  const toast = useToast();
  const location = useLocation();
  const param = useParams();
  const stateTab: string = param.tab;
  const searchParams = new URLSearchParams(location.search);
  const isGroupByJob = !!searchParams.get('groupRowId');
  const filter = searchParams?.get('filter');
  const { candidateData } = useSelector((state: any) => state.candidates);

  const {
    isOpen: isOpenSubmit,
    onClose: onCloseSubmit,
    onOpen: onOpenSubmit,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenScheduleInterview,
    onOpen: onOpenScheduleInterview,
    onClose: onCloseScheduleInterview,
  } = useDisclosure();
  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();
  const {
    isOpen: isOpenHired,
    onOpen: onOpenHired,
    onClose: onCloseHired,
  } = useDisclosure();

  const { candidateDetails, isLoadingCandidate, refetch } = useGetCandidate();
  const { data: jobDescription } = useFetchJobByIdQuery(
    { id: jobId },
    {
      skip: !Boolean(jobId),
      selectFromResult: ({ data, ...other }) => ({
        ...other,
        data: {
          ...data,
          contactOption: [
            {
              label: [
                data?.data?.lead?.first_name,
                data?.data?.lead?.last_name,
              ].join(' '),
              value: data?.data?.lead?.primary_email,
            },
          ],
        },
      }),
    }
  );

  const { data: candidateStatusList, isLoading: isLoadingCandidateStatus } =
    useFetchCandidateStatusQuery(
      {},
      {
        selectFromResult: ({ data, ...other }) => ({
          ...other,
          data:
            data
              ?.filter((candidate) => candidate.status !== 'Interview')
              ?.map?.((status) => ({
                label: status.status,
                id: status.id,
                options:
                  status?.sub?.map((sub) => ({
                    label: sub.sub_status,
                    value: sub.id,
                  })) ?? [],
              })) ?? [],
        }),
      }
    );
  const [emailSubject, setEmailSubject] = useState('');
  const [reqAgreement] = useLazyFetchClientAgreementsQuery();
  const [reqStatusChange] = useChangeCandidateSubStatusMutation();
  const [reqReject] = useRejectCandidateMutation();
  const [reqUnReject] = useUnrejectCandidateMutation();

  const jobDetails = candidateDetails?.currentDisplayJob;
  const counts = useMemo(() => {
    let unreject =
      jobId && jobDetails?.candidate_status_id === ALLOWED_UNREJECT_STATUS
        ? 1
        : isGroupByJob
        ? false
        : candidateDetails?.rejectedJobs?.length;

    return {
      unreject,
    };
  }, [candidateDetails]);

  const display = useMemo(() => {
    return {
      recruiterReject:
        jobDetails?.candidate_status_id !== ALLOWED_UNREJECT_STATUS,
      interview: ALLOWED_SCHEDULE_INTERVIEW_STATUS.includes(
        jobDetails?.candidate_status_id
      ),
      unreject: jobDetails?.candidate_status_id === ALLOWED_UNREJECT_STATUS,
      reject: jobDetails?.candidate_status_id !== ALLOWED_UNREJECT_STATUS,
      submit: ALLOWED_SUBMIT_TO_CLIENT_STATUS.includes(
        jobDetails?.candidate_status_id
      ),
    };
  }, [counts]);

  function handleScheduleInterview() {
    setEmailSubject(
      `Video Interview | ${jobDetails?.job?.title} | ${[
        candidateDetails?.first_name,
        candidateDetails?.last_name,
      ].join(' ')} / ${jobDetails?.job?.client?.name}`
    );
    onOpenScheduleInterview();
  }

  async function onCandidateReject(
    asscociateId: number,
    reason: number | null
  ) {
    const rejectCandidate = reqReject({
      id: asscociateId,
      data: {
        reason: reason,
      },
    })
      .unwrap()
      .then(() => {
        refetch();
        if (isGroupByJob) {
          const totalJobGroup = parseInt(searchParams.get('totalGroup'));
          const currentSelectedGroup = parseInt(searchParams.get('groupRowId'));
          const totalCandidates = parseInt(searchParams.get('total'));
          const currentCandidate = parseInt(searchParams.get('rowId'));
          //reached last group & last candidate

          if (
            currentCandidate + 1 === totalCandidates &&
            currentSelectedGroup + 1 === totalJobGroup
          ) {
            onClose();
          } else {
            handleNextData();
          }
        } else {
          handleNextData();
        }
      });
    toast.promise(rejectCandidate, {
      loading: { title: 'Rejecting...' },
      error: { title: 'Something went wrong' },
      success: { title: 'Successfully rejected!' },
    });
  }
  async function onCandidateUnReject(id: number) {
    const rejectCandidate = reqUnReject({
      id,
      data: [],
    })
      .unwrap()
      .then(() => {
        refetch();
        onClose();
      });
    toast.promise(rejectCandidate, {
      loading: { title: 'Un-Rejecting...' },
      error: { title: 'Something went wrong' },
      success: { title: 'Successfully un-rejected!' },
    });
  }
  async function handleHiredStatus() {
    //when HIRED
    const agreement = reqAgreement({
      data: { page: 1, take: 50, query: '' },
      id: jobDetails?.job?.client_id,
    })
      .unwrap()
      .then(() => {
        onOpenHired();
      });
    toast.promise(agreement, {
      loading: { title: 'Loading agreement...' },
      error: { title: 'Something went wrong' },
      success: { duration: 0, title: 'Loaded agreement!' },
    });
  }
  async function handleChangeStatus(subId: number, associateId: number) {
    const changeStatus = reqStatusChange({
      data: { sub_id: subId },
      id: associateId,
    })
      .unwrap()
      .then(() => {
        refetch();
        handleNextData();
      });
    toast.promise(changeStatus, {
      loading: { title: 'Changing status...' },
      error: { title: 'Something went wrong' },
      success: { title: 'Successfully changed status!' },
    });
  }

  async function handleUnrejects() {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'You are about to unreject this candidate.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (!confirm) return;
    onCandidateUnReject(jobDetails?.assoc_id || jobDetails?.id);
  }

  return (
    <>
      {!isLoadingCandidate && ( //drawer loader
        <>
          {/* job status */}
          {/* - job page, search tab,, dont display */}
          {/* - in candidates view tab, has job, selected filter */}
          <RenderIf condition={true}>
            <Menu closeOnSelect={true} isLazy>
              <Flex>
                <Box
                  sx={{
                    button: {
                      p: '4px',
                      pl: '8px',
                      height: '35px',
                      fontSize: '12px',
                      borderRight: 'none',
                      borderRadius: '6px 0 0 6px',
                    },
                  }}
                >
                  <Button loading={isLoadingCandidateStatus}>
                    {jobDetails?.sub?.sub_status || '--'}
                  </Button>
                </Box>
                <MenuButton>
                  <Box
                    sx={{
                      button: {
                        p: '4px 8px',
                        height: '35px',
                        fontSize: '12px',
                        borderLeft: 'none',
                        borderRadius: '0 6px 6px 0',
                      },
                    }}
                  >
                    <Button rightIcon="chevron-down">| </Button>
                  </Box>
                </MenuButton>
              </Flex>

              <MenuList
                fontSize="sm"
                maxHeight="120px"
                overflowY="auto"
                sx={{
                  '.chakra-menu__group__title': {
                    margin: '0.4rem 0.8rem',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '10px',
                    color: 'default.gray.400',
                    textTransform: 'uppercase',
                  },
                }}
              >
                {candidateStatusList.map((val, key: number) => {
                  return (
                    <MenuGroup title={val.label} key={`stat-option-${key}`}>
                      {val.options.map((op, index: number) => {
                        return (
                          <MenuItem
                            onClick={() =>
                              op.value === 8
                                ? handleHiredStatus()
                                : handleChangeStatus(
                                    op.value,
                                    jobDetails?.assoc_id || jobDetails?.id
                                  )
                            }
                            key={`stat-option-${key}-${index}`}
                          >
                            {op.label}
                          </MenuItem>
                        );
                      })}
                    </MenuGroup>
                  );
                })}
              </MenuList>
            </Menu>
          </RenderIf>
          <RenderIf condition={display.submit}>
            <Flex alignItems="center" justifyContent="center">
              <Box
                sx={{
                  button: {
                    height: '35px',
                    fontSize: '12px',
                    pl: '8px',
                  },
                }}
              >
                <Button
                  leftIcon="arrow-right"
                  size="sm"
                  onClick={() => onOpenSubmit()}
                >
                  Submit to Client
                </Button>
              </Box>
            </Flex>
          </RenderIf>
          <RenderIf condition={display.interview}>
            <Flex>
              <Box
                sx={{
                  button: {
                    height: '35px',
                    fontSize: '12px',
                    pl: '8px',
                  },
                }}
              >
                <Button
                  leftIcon="video"
                  size="sm"
                  onClick={() => handleScheduleInterview()}
                >
                  Schedule Interview
                </Button>
              </Box>
            </Flex>
          </RenderIf>
          {/* <RenderIf condition={display.reject}>
              <Box
                sx={{
                  button: {
                    position: 'relative',
                    p: '4px 8px',
                    pl: '8px',
                    height: '35px',
                    fontSize: '12px',
                    borderRadius: '6px',
                  },
                }}
              >
                <Button
                  leftIcon="circle-xmark"
                  size="sm"
                  onClick={() => handleRejects()}
                >
                  <Box>Reject</Box>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-8px',
                      float: 'right',
                      background: '#7e5ec2',
                      borderRadius: '4px',
                      padding: '3px 5px',
                      display: 'flex',
                      fontSize: '10px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    {counts.reject}
                  </Box>
                </Button>
              </Box>
            </RenderIf> */}
          <RenderIf condition={display.recruiterReject}>
            <Menu closeOnSelect={true}>
              <Flex>
                <Box
                  sx={{
                    button: {
                      p: '4px',
                      pl: '8px',
                      height: '35px',
                      fontSize: '12px',
                      borderRight: 'none',
                      borderRadius: '6px 0 0 6px',
                    },
                  }}
                >
                  <Button
                    onClick={() =>
                      onCandidateReject(
                        jobDetails?.assoc_id || jobDetails?.id,
                        14
                      )
                    }
                  >
                    Recruiter Reject
                  </Button>
                </Box>
                <MenuButton>
                  <Box
                    sx={{
                      button: {
                        p: '4px 8px',
                        height: '35px',
                        fontSize: '12px',
                        borderLeft: 'none',
                        borderRadius: '0 6px 6px 0',
                      },
                    }}
                  >
                    <Button rightIcon="chevron-down">|</Button>
                  </Box>
                </MenuButton>
              </Flex>

              <MenuList fontSize="sm" maxHeight="120px" overflowY="auto">
                <MenuItem
                  onClick={() =>
                    onCandidateReject(
                      jobDetails?.assoc_id || jobDetails?.id,
                      15
                    )
                  }
                >
                  Client Reject
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    onCandidateReject(
                      jobDetails?.assoc_id || jobDetails?.id,
                      16
                    )
                  }
                >
                  Candidate Not Interested
                </MenuItem>
              </MenuList>
            </Menu>
          </RenderIf>
          <RenderIf condition={display.unreject}>
            <Box
              sx={{
                button: {
                  position: 'relative',
                  p: '4px 8px',
                  pl: '8px',
                  height: '35px',
                  fontSize: '12px',
                  borderRadius: '6px',
                },
              }}
            >
              <Button
                leftIcon="circle-xmark"
                size="sm"
                onClick={() => handleUnrejects()}
              >
                <Box>Un - Reject</Box>
                {stateTab && !isGroupByJob && !filter && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-8px',
                      float: 'right',
                      background: '#7e5ec2',
                      borderRadius: '4px',
                      padding: '3px 5px',
                      display: 'flex',
                      fontSize: '10px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    {counts.unreject}
                  </Box>
                )}
              </Button>
            </Box>
          </RenderIf>
          {/* {isOpenJobList && (
            <CandidateJobList
              jobs={jobList}
              isOpen={isOpenJobList}
              onClose={onCloseJobList}
              onSelect={(e: any) => {
                handleJobSelect(e);
                // onJobSelect(e);
              }}
              type={actionType}
            />
          )} */}
          {isOpenSubmit && (
            <SubmitToClientModal
              isOpen={isOpenSubmit}
              onClose={onCloseSubmit}
              candidate={candidateDetails}
              job_id={jobId}
              callback={() => {
                handleNextData();
                // callBackAction();
              }}
            />
          )}
          {isOpenScheduleInterview && (
            <CandidatesInterviewModal
              isOpen={isOpenScheduleInterview}
              onClose={onCloseScheduleInterview}
              associate={jobDetails?.assoc_id || jobDetails?.id}
              subject={emailSubject} //not getting used
              isJobView={true}
              jobsId={jobId}
              contactOptionValue={jobDescription?.contactOption}
              callback={() => {
                onCloseScheduleInterview();
                refetch();
                // callBackAction();
              }}
              isEdit={false}
            />
          )}
          {isOpenReject && (
            <RejectModal
              isOpen={isOpenReject}
              onClose={onCloseReject}
              jobsId={jobId}
              id={jobDetails?.assoc_id || jobDetails?.id}
              rejectType={jobDetails?.sub?.target}
              callback={() => {
                // callBackAction();
              }}
            />
          )}
          {isOpenHired && (
            <HiredModal
              isOpen={isOpenHired}
              jobId={jobId}
              onClose={onCloseHired}
              candidate={candidateData}
              onCallback={() =>
                handleChangeStatus(8, jobDetails?.assoc_id || jobDetails?.id)
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default ActionButton;
