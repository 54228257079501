/* eslint-disable react-hooks/exhaustive-deps */

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import {
  setPairTemplate,
  usePlaceHolderPairMutation,
  useRawListTemplateMutation,
} from 'store/template.slice';

import { EmailsModalProps } from './types';
import {
  updateInbox,
  useGetInboxCandidateMutation,
  usePostReplyTaskMutation,
  usePostTaskListMutation,
} from 'store/inbox.slice';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router';
import {
  useBulkSendEmailCandidateMutation,
  useSendEmailCandidateMutation,
} from 'store/candidates.slice';
import { cleanUpload, uploadList } from 'store/uploads.slice';
import escapeRegExp from 'utils/escapeRegExp';

import * as Yup from 'yup';
import { replacePlaceholders } from 'utils/placeholders';
import { replaceSpansWithPlaceholders } from '../AIBody/utils';
import useTemplate from '../hooks/useTemplate';

export const useEmailsModalServices = ({
  isOpen,
  onClose,
  email,
  id,
  candidate_id,
  job_id,
  isReply,
  idList,
  isBulkEmail,
  emailList,
  subject,
}: EmailsModalProps) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const { placehoderPair } = useSelector((state: any) => state.template);
  const { userList } = useSelector((state: any) => state.user);
  const { inboxList, inboxPgBtn, candidateData } = useSelector(
    (state: any) => state.inbox
  );
  const { candidateDataJobId } = useSelector((state: any) => state.candidates);

  const { uploaded } = useSelector((state: any) => state.uploads);

  const { defaultTemplate, isTemplatesFetching, templates, refetchTemplates } =
    useTemplate({ groupId: 1 });
  const templateList = useMemo(() => {
    return (
      templates?.map((template: any) => ({
        value: template.id,
        label: template.title,
        subject: template.subject,
        body: template.body,
        attachments: template.attachments,
      })) ?? []
    ).concat({
      value: null,
      label: 'Create New Template',
      subject: '',
      body: '',
      attachments: null,
    });
  }, [templates]);

  const [reqPostTaskList] = usePostTaskListMutation();
  const [reqPostReplyTask, resPostReplyTask] = usePostReplyTaskMutation();
  const [reqEmail, resEmail] = useSendEmailCandidateMutation();
  const [reqBulkEmail, resBulkEmail] = useBulkSendEmailCandidateMutation();
  const [reqPair] = usePlaceHolderPairMutation();
  const [reqGetCandidates] = useGetInboxCandidateMutation();

  const [placehoder, setPlaceHoders] = useState(placehoderPair);
  const [ccBcc, setCcBcc] = useState([]);
  const [bcc, setBcc] = useState([]);

  const [bccValue, setBccValue] = useState([]);
  const [ccValue, setCcValue] = useState([]);

  const initialValues: any = useMemo(() => {
    return {
      subject: defaultTemplate?.subject || subject,
      body: defaultTemplate?.body ?? '',
      to: isBulkEmail ? emailList?.join('; ') : email,
      cc: [] as any,
      bcc: [] as any,
      template: defaultTemplate ?? null,
    };
  }, [defaultTemplate, emailList, email, subject, isBulkEmail]);

  const [attachments, setAttachments] = useState([]);
  const [tokenLink, setTokenLink] = useState('');
  const [errMsg, setErrorMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: openAlert,
  } = useDisclosure({ defaultIsOpen: false });

  const placholders = (text: string) => {
    return replacePlaceholders(text, placehoder);
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      subject: Yup.string().required('Subject is required.'),
      body: Yup.string().required('Body is required.'),
      template: Yup.object()
        .shape({ label: Yup.string(), value: Yup.number() })
        .required('Template is required.'),
      ...(!isBulkEmail && {
        to: Yup.string()
          .email('Invalid email format')
          .required('Email is required.'),
      }),
    })
  );

  const replaceWithPlaceholder = (text: string) => {
    for (const placeholder in placehoder) {
      if (placehoder[placeholder] !== '') {
        const escapedPlaceholder = escapeRegExp(
          String(placehoder[placeholder])
        );
        const regex = new RegExp(escapedPlaceholder, 'g');
        text = text?.replace(regex, `{{${placeholder}}}`);
      }
    }
    return text;
  };

  const replyEmail = (submitData: any) => {
    const data = { ...submitData };
    delete data.template;
    if (isReply) {
      let cc: any = [];
      if (ccValue && ccValue?.length > 0) {
        ccValue.map((item: any) => {
          cc.push(item.value);
        });
      }
      let bcc: any = [];
      if (bccValue && bccValue?.length > 0) {
        bccValue.map((item: any) => {
          bcc.push(item.value);
        });
      }

      data['attachments'] = uploaded;

      const payload = {
        ...data,
        subject: placholders(data.subject),
        body: placholders(replaceSpansWithPlaceholders(data.body)),
        bcc: bcc,
        cc: cc,
        att_template: attachments || [],
      };

      payload.reply = payload.body;

      reqPostReplyTask({ data: payload, id });
    } else {
      data['attachments'] = uploaded || [];
      data['att_template'] = attachments || [];
      const toRecipients = data.to && data.to?.split('; ');
      setTokenLink('');
      let cc: any = [];
      if (ccValue && ccValue?.length > 0) {
        ccValue.map((item: any) => {
          cc.push(item.value);
        });
      }
      let bcc: any = [];
      if (bccValue && bccValue?.length > 0) {
        bccValue.map((item: any) => {
          bcc.push(item.value);
        });
      }
      const newParam = {
        ...data,
        to: toRecipients,
        bcc: bcc,
        cc: cc,
        body: replaceSpansWithPlaceholders(data.body),
        att_template: attachments || [],
      };

      if (isBulkEmail) {
        newParam.idList = idList;
        newParam.subject = replaceWithPlaceholder(data.subject);
        reqBulkEmail({ data: newParam });
      } else {
        newParam.subject = placholders(data.subject);
        newParam.body = placholders(newParam.body);
        reqEmail({ data: newParam, id });
      }
    }
  };

  const getPrimaryMobile = (contact: any) => {
    try {
      let mobile = '';

      contact.forEach((item: any) => {
        if (item.primary && item.type === 'mobile') {
          mobile = item?.number;
        }
      });

      return mobile;
    } catch (e) {
      return '';
    }
  };

  const candidatePairing = async (param: any) => {
    const newpair = {
      ...placehoderPair,
      candidate_full_name: param.candidate_full_name,
      candidate_first_name: param.candidate_first_name,
      candidate_email: param.candidate_email,
      candidate_current_position: param.candidate_current_position,
      candidate_current_company: param.candidate_current_company,
      candidate_mobile: param.candidate_mobile,
      candidate_linkedin_url: param.candidate_linkedin_url,
      candidate_work_status: param.candidate_work_status,
      candidate_city: param.candidate_city,
      candidate_state: param.candidate_state,
      candidate_degree: param.candidate_degree,
      candidate_total_years_of_exp: param.candidate_total_years_of_exp,
      job_url: param.job_url,
      job_name_url: param.job_name_url,
      client_job_title: param.client_job_title,
      job_location: param.job_location,
      internal_job_description: param.internal_job_description,
      job_contact_name: param.job_contact_name,
      job_contact_first_name: param.job_contact_first_name,
      job_contact_title: param.job_contact_title,
      job_company_name: param.job_company_name,
      job_primary_recruiter_name: param.job_primary_recruiter_name,
      job_ad_description: param.job_ad_description,
      job_posting_title: param.job_posting_title,
      email_signature: param.email_signature,
      job_company_website: param.job_company_website,
      job_company_address: param.job_company_address,
      job_industry: param.job_industry,
      job_posting_date: param.job_posting_date,
      job_closing_date: param.job_closing_date,
      candidate_status: param.candidate_status,
      candidate_source: param.candidate_source,
      candidate_recruiter_notes: param.candidate_recruiter_notes,
      job_requirements: param.job_requirements,
      application_process: param.application_process,
      hiring_manager_notes: param.hiring_manager_notes,
    };

    // setPlaceHoders(newpair);
    await dispatch(setPairTemplate({ placehoderPair: newpair }));
  };

  const handleClose = () => {
    onClose();
    navigate('/inbox/emails');
    dispatch(updateInbox({ inboxData: {} }));
  };
  const templateCallback = (setFieldValue: any) => {
    refetchTemplates().then((res: any) => {
      const data = res.data.data;

      const highestIdIndex = data.reduce(
        (maxIndex: any, item: any, index: any, array: any) =>
          item.id > array[maxIndex].id ? index : maxIndex,
        0
      );

      const temp = data[highestIdIndex];
      setFieldValue('template', { ...temp, value: temp.id, label: temp.title });
      setFieldValue('body', temp.body);
      setFieldValue('subject', temp.subject);
    });
  };
  useEffect(() => {
    if (isOpen) {
      const data = {
        candidate_id: candidate_id,
        job_id: job_id || candidateDataJobId,
      } as any;
      reqPair(data);
    }
  }, [isOpen]);

  useEffect(() => {
    setPlaceHoders(placehoderPair);
  }, [placehoderPair]);

  useEffect(() => {
    reqGetCandidates({ id: candidate_id });
    setTokenLink('');
    dispatch(cleanUpload({ attachments: [] }));
    dispatch(cleanUpload({ uploaded: [] }));

    if (candidateData) {
      const candidate_full_name =
        [candidateData?.data?.first_name, candidateData?.data?.last_name]
          .filter(Boolean)
          .join(', ') ??
        [candidateData?.first_name, candidateData?.last_name]
          .filter(Boolean)
          .join(', ') ??
        '';
      const candidate_first_name =
        candidateData?.data?.first_name ??
        candidateData?.first_name ??
        placehoderPair?.first_name ??
        '';
      const candidate_email =
        candidateData?.data?.primary_email ??
        candidateData?.primary_email ??
        placehoderPair?.candidate_email ??
        '';
      const candidate_current_position =
        candidateData?.data?.latest_job_title ??
        candidateData?.latest_job_title ??
        placehoderPair?.candidate_current_position ??
        '';
      const candidate_current_company =
        candidateData?.data?.latest_employer ??
        candidateData?.latest_employer ??
        placehoderPair?.candidate_current_company ??
        '';

      const candidate_mobile = placehoderPair?.candidate_mobile
        ? String(placehoderPair?.candidate_mobile)
        : String(getPrimaryMobile(candidateData?.data?.contact)) ??
          String(getPrimaryMobile(candidateData?.contact)) ??
          '';
      const candidate_linkedin_url =
        candidateData?.data?.linkedin_url ??
        candidateData?.linkedin_url ??
        placehoderPair?.candidate_linkedin_url ??
        '';
      const candidate_work_status =
        candidateData?.data?.work_status?.status ??
        candidateData?.work_status?.status ??
        placehoderPair?.candidate_work_status ??
        '';
      const candidate_city =
        candidateData?.data?.city ??
        candidateData?.city ??
        placehoderPair?.candidate_city ??
        '';
      const candidate_state =
        candidateData?.state_province ?? placehoderPair?.candidate_state ?? '';
      let candidate_degree =
        candidateData?.data?.education?.[0]?.degree_level?.name ??
        placehoderPair?.candidate_degree ??
        '';
      // if (candidateData?.education?.length > 0) {
      //   candidate_degree = ;
      // }
      const candidate_total_years_of_exp =
        candidateData?.total_year_of_experience ??
        placehoderPair?.candidate_total_years_of_exp ??
        '';
      const job_title = placehoderPair?.client_job_title
        ? placehoderPair?.client_job_title
        : '';
      const job_location = placehoderPair?.job_location
        ? placehoderPair?.job_location
        : '';
      const job_contact_name = placehoderPair?.job_contact_name
        ? placehoderPair?.job_contact_name
        : '';
      const job_contact_first_name = placehoderPair?.job_contact_first_name
        ? placehoderPair?.job_contact_first_name
        : '';
      const job_company_name = placehoderPair?.job_company_name
        ? placehoderPair?.job_company_name
        : '';
      const job_primary_recruiter_name =
        placehoderPair?.job_primary_recruiter_name
          ? placehoderPair?.job_primary_recruiter_name
          : '';
      const job_contact_title = placehoderPair?.job_contact_title
        ? placehoderPair?.job_contact_title
        : '';
      const internal_job_description = placehoderPair?.internal_job_description
        ? placehoderPair?.internal_job_description
        : '';
      const job_ad_description = placehoderPair?.job_ad_description
        ? placehoderPair?.job_ad_description
        : '';

      const job_url = placehoderPair?.job_url ? placehoderPair?.job_url : '';
      const job_name_url = placehoderPair?.job_name_url
        ? placehoderPair?.job_name_url
        : '';

      const candidateParam = {
        candidate_full_name,
        candidate_first_name,
        candidate_email,
        candidate_current_position,
        candidate_current_company,
        candidate_mobile,
        candidate_linkedin_url,
        candidate_work_status,
        candidate_city,
        candidate_state,
        candidate_degree,
        candidate_total_years_of_exp,
        job_url,
        job_name_url,
        client_job_title: job_title,
        job_location,
        job_contact_name,
        job_contact_first_name,
        job_company_name,
        job_primary_recruiter_name,
        job_ad_description,
        job_contact_title,
        internal_job_description,
      } as any;

      candidatePairing(candidateParam);
    }

    let ccOption = [] as any;
    Object.values(userList).map((item: any) => {
      ccOption.push({
        label: `${[item.first_name, item.last_name]
          .filter(Boolean)
          .join(' ')} <${item.email}>`,
        value: item.email,
      });
    });
    setCcBcc(ccOption);
    setBcc(ccOption);
  }, [id]);

  useEffect(() => {
    if (resEmail.isError || resBulkEmail.isError) {
      const error: any = resEmail.error;
      try {
        const message =
          error?.data?.error?.message ||
          error?.data?.data?.message ||
          'Unkown error';

        if (error?.data?.data?.type === 'redirect') {
          setTokenLink(error?.data?.data?.url);
        }
        setErrorMsg(message);
        openAlert();
      } catch (e) {
        console.error('error', e);
      }
    } else {
      setErrorMsg('');
    }
  }, [resEmail.isError, resBulkEmail.isError]);

  useEffect(() => {
    if (resEmail.isSuccess || resBulkEmail.isSuccess) {
      toast({
        title: 'Email sent',
        description: 'We have sent your email',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });

      dispatch(uploadList({ uploaded: [], attachments: [] }));
      onClose();
    }
  }, [resEmail.isSuccess, resBulkEmail.isSuccess]);

  useEffect(() => {
    if (resPostReplyTask.isSuccess) {
      if (params['*'].includes('inbox/')) {
        reqPostTaskList(inboxPgBtn);

        const rowId = inboxList.findIndex(
          (val: any) => val.id === Number(params.settingTabId)
        );
        const inboxId = inboxList[rowId + 1]?.id;
        const lastId =
          inboxList[inboxList.length - 1]?.id === Number(params.settingTabId);

        if (inboxList.length === 1 || lastId) {
          dispatch(updateInbox({ inboxData: {} }));
        } else {
          navigate(`${params.settingTab}/${inboxId}`);
        }

        toast({
          title: 'Task reply send.',
          description: "We've send your reply.",
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
      dispatch(uploadList({ uploaded: [], attachments: [] }));
      onClose();
    }
  }, [resPostReplyTask.isSuccess, params]);

  return {
    initialValues,
    replyEmail,
    validationSchema,
    ccBcc,
    template: templateList,
    attachments,
    setAttachments,
    placholders,
    bcc,
    isLoading:
      resPostReplyTask.isLoading ||
      resEmail.isLoading ||
      resBulkEmail.isLoading,
    handleClose,
    isOpenAlert,
    onCloseAlert,
    openAlert,
    tokenLink,
    errMsg,
    templateCallback,
    setBccValue,
    setCcValue,
    isTemplatesFetching,
  };
};
