// Chakra UI components
import {
  Badge,
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

// React Icons
import { BiAlarm } from 'react-icons/bi';

// Components from app
import JobsDrawer from 'modules/Jobs/Layout/JobModule/Drawer';
import LoadingPage from 'components/app/Loading';
import { PaidJobModal } from 'components/app/Jobs/Modal/PaidJob';

// Components from lib
import FAIcon from 'components/lib/FAIcon';

// Library components
import Button from 'Library/Button';
import Pagination from 'Library/Pagination';

// Assets
import linkedIn from 'assets/images/linkedin.svg';
import jobBoard from 'assets/images/job-board.svg';
import careersPage from 'assets/images/careers-page.svg';
import checkmarkCircle from 'assets/images/checkCircle.svg';
import xmarkCircle from 'assets/images/xCircle.svg';
import warnmarkCircle from 'assets/images/warnCircle.svg';
import hourglassTop from 'assets/images/hourglassTop.svg';
import Fire from 'assets/images/Fire.png';

// Local components

import ManualResumeDrawer from '../../../../../components/app/Jobs/Modal/ManualResume';

// Utilities
import { getDaysRemaining } from 'utils/getDaysRemaining';
import { useJobsTableServices } from './useJobsTableServices';
import { FollowUpModal } from 'components/app/Jobs/Modal/FollowUp';
import AddJobForm from 'modules/Jobs/components/job-form/AddJobForm';
import {
  useCreateFullJobMutation,
  useCreateQuickJobMutation,
  useUpdateFullJobMutation,
  useUpdateQuickJobMutation,
} from 'services/jobs/job.mutation';
import PreFilledJobForm from 'modules/Jobs/components/job-form/EditJobForm';

interface JobTableProps {
  isOpenModal: boolean;
  onOpenModal: () => void;
  onCloseModal: () => void;
  modalJobTitle: string;
}

export const iconStyle = {
  width: '20px',
  height: '20px',
  objectFit: 'contain',
};

export const smallIconStyle = {
  width: '18px',
  height: '18px',
  objectFit: 'contain',
};

export const hourglassIconStyle = {
  width: '12px',
  height: '12px',
  objectFit: 'contain',
};

export default function JobsTable({
  isOpenModal,
  onOpenModal,
  onCloseModal,
  modalJobTitle,
}: JobTableProps) {
  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();

  const {
    isOpen: isEditJobOpen,
    onOpen: onEditJobOpen,
    onClose: onEditJobClose,
  } = useDisclosure();

  const {
    isOpen: isFollowUpOpen,
    onOpen: onFollowUpOpen,
    onClose: onFollowUpClose,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isManualResumeOpen,
    onOpen: onManualResumeOpen,
    onClose: onManualResumeClose,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenPaid,
    onOpen: onOpenPaid,
    onClose: onClosePaid,
  } = useDisclosure();

  const {
    isLoading,
    jobPgBtn,
    jobDataState,
    viewCandidateClick,
    trViewCandidate,
    handleClick,
    locationParse,
    handleEdit,
    handleViewCandidate,
    followUpClick,
    handleDuplicate,
    handleToggleStatus,
    DelJobs,
    handleClickClientTd,
    handleClickClientLink,
    handleOpenPaid,
    handleManualParse,
    rowId,
    jobs,
    handlePrevData,
    handleNextData,
    jobPage,
    pageChange,
    entryChange,
    modalTitle,
    isDuplicate,
    jobId,
    isFollowUpAll,
    followUpParam,
    setIsFollowUp,
    handleUpdateJobList,
  } = useJobsTableServices({
    onOpen: onOpenDrawer,
    onFollowUpOpen,
    onManualResumeOpen,
    onEditJobOpen,
    onOpenPaid,
  });
  const [reqCreateFullJob] = useCreateFullJobMutation();
  const [reqCreateQuickJob] = useCreateQuickJobMutation();
  const [reqUpdateFullJob] = useUpdateFullJobMutation();
  const [reqUpdateQuickJob] = useUpdateQuickJobMutation();
  const tableHeader: { label: React.ReactNode; width: string }[] = [
    { label: 'Client Name', width: '100px' },
    {
      label: (
        <Tooltip
          hasArrow
          label="Career Page"
          bg="default.gray.1000"
          placement="top"
        >
          <Image
            src={careersPage}
            alt="Talently Careers Page Logo"
            sx={iconStyle}
          />
        </Tooltip>
      ),
      width: '10px',
    },
    {
      label: (
        <Tooltip
          hasArrow
          label="Job Board"
          bg="default.gray.1000"
          placement="top"
          justifyContent="center"
          className="tooltip"
        >
          <Image src={jobBoard} alt="Job Seeker Logo" sx={smallIconStyle} />
        </Tooltip>
      ),
      width: '10px',
    },
    {
      label: (
        <Tooltip
          hasArrow
          label="Linkedin Ad"
          bg="default.gray.1000"
          placement="top"
          w="fit-content"
        >
          <Image src={linkedIn} alt="LinkedIn Logo" sx={smallIconStyle} />
        </Tooltip>
      ),
      width: '10px',
    },
    { label: 'Blasted', width: '10px' },
    { label: 'New', width: '10px' },
    { label: 'Submits', width: '10px' },
    { label: 'Interview', width: '10px' },
    { label: 'Campaign Status', width: '100px' },
    {
      label: (
        <Box>
          <Image
            src={hourglassTop}
            alt="Job Seeker Logo"
            sx={hourglassIconStyle}
          />
        </Box>
      ),
      width: '10px',
    },
  ];

  const filteredData = tableHeader.filter((item) => {
    return !jobPgBtn.job_ads && item.label === 'Job Boards' ? false : true;
  });

  async function onSubmitCreateForm(formField: any) {
    try {
      const res = await (formField.job_type_id === 1
        ? reqCreateFullJob(formField)
        : reqCreateQuickJob(formField)
      ).unwrap();
      handleUpdateJobList();
      return res;
    } catch (err: any) {
      return err.data;
    }
  }
  async function onSubmitUpdateForm(formField: any, jobId: number) {
    try {
      const payload = { data: formField, id: jobId };
      const res = await (formField.job_type_id === 1
        ? reqUpdateFullJob(payload)
        : reqUpdateQuickJob(payload)
      ).unwrap();
      handleUpdateJobList();
      return res;
    } catch (err: any) {
      return err.data;
    }
  }

  return (
    <Stack
      spacing={0}
      sx={{
        height: '100%',
        w: 'calc(100% - 300px)',
        bgColor: '#fff',
        borderRadius: '10px',
        p: '16px',
      }}
    >
      <Flex justifyContent="flex-end" mb="16px">
        {isLoading && <LoadingPage />}
        <CreateJobBtn onSubmit={onSubmitCreateForm} />
      </Flex>

      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="inherit"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead sx={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <Tr bg="default.white.600">
              <Th
                sx={{
                  position: 'sticky',
                  left: 0,
                  bg: 'default.white.600',
                  color: 'default.gray.600',
                  boxShadow: 'inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)',
                }}
              >
                <Flex gap="15px">
                  <Box width="14px" />
                  <Box width="10.5px" />
                  <Box>Job Details</Box>
                </Flex>
              </Th>
              {(jobPgBtn.job_ads ? tableHeader : filteredData).map(
                (title, key: number) => (
                  <Th key={title.label + '-' + key}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                )
              )}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {jobDataState.map((job: any, key: number) => {
              return (
                <Tr
                  key={`jobsTable-${key}`}
                  _hover={{
                    bg: '#f8f9fa',
                  }}
                  cursor="pointer"
                >
                  <Td
                    position="sticky"
                    left={0}
                    bg="white"
                    boxShadow="inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)"
                    _hover={{
                      bg: '#f8f9fa',
                    }}
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <Flex alignItems="center" width="100%">
                        <Flex
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          width="300px"
                          onClick={(e: any) => viewCandidateClick(e, job)}
                        >
                          <Flex gap="15px">
                            {job.job.job_type_id === 2 ? (
                              <Image
                                src={Fire}
                                alt="Fire"
                                height="14px"
                                mt="2px"
                              />
                            ) : (
                              <Box width="14px" />
                            )}
                            <Tooltip
                              label={
                                job.job_status_id === 1
                                  ? 'Open'
                                  : job.job_status_id === 5
                                  ? 'Draft'
                                  : 'Closed'
                              }
                            >
                              <Box
                                fontSize="12px"
                                color={
                                  job.job_status_id === 1
                                    ? 'success.1000'
                                    : job.job_status_id !== 5
                                    ? '#D5605D'
                                    : '#999999'
                                }
                              >
                                <FAIcon iconName="circle" />
                              </Box>
                            </Tooltip>
                            <Box>
                              <Flex
                                fontWeight="bold"
                                fontSize="16px"
                                color="default.primarytext"
                                gap="10px"
                              >
                                <Box
                                  onClick={() => trViewCandidate(job.id)}
                                  sx={{
                                    maxWidth: '170px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {job.title}
                                </Box>
                              </Flex>
                              <Box
                                fontSize="14px"
                                color="default.secondarytext"
                              >
                                {job.job?.serial_number}
                              </Box>
                              <Box
                                fontSize="14px"
                                color="default.secondarytext"
                              >
                                {job?.job?.location &&
                                  locationParse(job.job.location)}
                              </Box>
                            </Box>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Menu preventOverflow={true}>
                        <MenuButton h="fit-content">
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </MenuButton>

                        <Portal>
                          <MenuList fontSize="sm">
                            <EditJobBtn
                              jobId={parseInt(job.id)}
                              onSubmit={(data) =>
                                onSubmitUpdateForm(data, parseInt(job.id))
                              }
                            />
                            <MenuItem onClick={() => handleViewCandidate(job)}>
                              <Flex
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <FAIcon iconName="eye" />
                                <Box ml={12} cursor="pointer">
                                  View Candidates
                                </Box>
                              </Flex>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                followUpClick({
                                  open: true,
                                  all: false,
                                  job_id: Number(job.id),
                                  lead_id: Number(job?.job?.lead_id) || null,
                                });
                              }}
                            >
                              <Flex
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <FAIcon iconName="envelope" />
                                <Box ml={12} cursor="pointer">
                                  Follow-up
                                </Box>
                              </Flex>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                followUpClick({
                                  open: true,
                                  all: true,
                                  job_id: Number(job.id),
                                  lead_id: Number(job?.job?.lead_id) || null,
                                });
                              }}
                            >
                              <Flex
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <FAIcon iconName="envelope" />
                                <Box ml={12} cursor="pointer">
                                  Follow-up for All Jobs
                                </Box>
                              </Flex>
                            </MenuItem>

                            <DuplicateJobBtn
                              jobId={parseInt(job.id)}
                              onSubmit={onSubmitCreateForm}
                            />

                            {job.job_status_id !== 5 && (
                              <MenuItem
                                onClick={() => handleToggleStatus(job)}
                                color={
                                  job.job_status_id === 1
                                    ? 'caution.800'
                                    : 'success.1000'
                                }
                              >
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  {job.job_status_id === 1 ? (
                                    <>
                                      <FAIcon iconName="ban" />
                                      <Box ml={12} cursor="pointer">
                                        Mark as Closed
                                      </Box>
                                    </>
                                  ) : (
                                    <>
                                      <FAIcon iconName="check" />
                                      <Box ml={12} cursor="pointer">
                                        Mark as Open
                                      </Box>
                                    </>
                                  )}
                                </Flex>
                              </MenuItem>
                            )}
                            <MenuItem
                              color="caution.800"
                              onClick={() => {
                                DelJobs(job);
                              }}
                            >
                              <Flex
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <FAIcon iconName="trash" />
                                <Box ml={12} cursor="pointer">
                                  Delete Job
                                </Box>
                              </Flex>
                            </MenuItem>
                          </MenuList>
                        </Portal>
                      </Menu>
                    </Flex>
                  </Td>

                  <Td onClick={(e: any) => handleClickClientTd(e, job.job)}>
                    <Box
                      onClick={handleClickClientLink}
                      className="clientClass"
                    >
                      <Link
                        href={`/clients/my-clients/${job.client?.id}/overview`}
                        target="_blank"
                      >
                        {job.client?.name}
                      </Link>
                    </Box>
                  </Td>
                  <Td onClick={() => handleClick(job.job)}>
                    {job?.jobads ? (
                      job.jobads?.[0]?.career_page_publish ? (
                        <Flex
                          color="success.1000"
                          w="18px"
                          h="18px"
                          alignItems="center"
                        >
                          <img
                            src={checkmarkCircle}
                            alt="Checkmark Circle"
                            style={{
                              width: '18px',
                              height: '18px',
                              objectFit: 'contain',
                            }}
                          />
                        </Flex>
                      ) : (
                        <Flex
                          color="default.gray.1000"
                          w="18px"
                          h="18px"
                          alignItems="center"
                        >
                          <img
                            src={xmarkCircle}
                            alt="Closemark Circle"
                            style={{
                              width: '18px',
                              height: '18px',
                              objectFit: 'contain',
                            }}
                          />
                        </Flex>
                      )
                    ) : (
                      <Flex
                        color="default.gray.1000"
                        w="18px"
                        h="18px"
                        alignItems="center"
                      >
                        <img
                          src={xmarkCircle}
                          alt="Closemark Circle"
                          style={{
                            width: '18px',
                            height: '18px',
                            objectFit: 'contain',
                          }}
                        />
                      </Flex>
                    )}
                  </Td>
                  <Td onClick={() => handleClick(job.job)}>
                    {job.jobads ? (
                      job.jobads?.[0]?.free_job_board_publish ? (
                        <Flex
                          color="success.1000"
                          w="18px"
                          h="18px"
                          alignItems="center"
                        >
                          <img
                            src={checkmarkCircle}
                            alt="Checkmark Circle"
                            style={{
                              width: '18px',
                              height: '18px',
                              objectFit: 'contain',
                            }}
                          />
                        </Flex>
                      ) : (
                        <Flex
                          color="default.gray.1000"
                          w="18px"
                          h="18px"
                          alignItems="center"
                        >
                          <img
                            src={xmarkCircle}
                            alt="Closemark Circle"
                            style={{
                              width: '18px',
                              height: '18px',
                              objectFit: 'contain',
                            }}
                          />
                        </Flex>
                      )
                    ) : (
                      <Flex
                        color="default.gray.1000"
                        w="18px"
                        h="18px"
                        alignItems="center"
                      >
                        <img
                          src={xmarkCircle}
                          alt="Closemark Circle"
                          style={{
                            width: '18px',
                            height: '18px',
                            objectFit: 'contain',
                          }}
                        />
                      </Flex>
                    )}
                  </Td>
                  <Td>
                    {job.jobads ? (
                      job.jobads?.[0]?.paid_job_board_publish ? (
                        job.jobads?.[0]?.job_expiry ? (
                          new Date(job.jobads?.[0]?.job_expiry) >=
                          new Date() ? (
                            <Flex
                              color="success.1000"
                              fontSize="20px"
                              alignItems="center"
                              gap="8px"
                            >
                              <img
                                src={checkmarkCircle}
                                alt="Checkmark Circle"
                                style={{
                                  width: '18px',
                                  height: '18px',
                                  objectFit: 'contain',
                                }}
                              />
                              <Tooltip
                                hasArrow
                                label={
                                  <Flex textAlign="center">
                                    The Linkedin job ad will expire in{' '}
                                    {getDaysRemaining(
                                      new Date(),
                                      new Date(job.jobads?.[0]?.job_expiry)
                                    )}{' '}
                                    day
                                    {getDaysRemaining(
                                      new Date(),
                                      new Date(job.jobads?.[0]?.job_expiry)
                                    ) > 1
                                      ? 's'
                                      : ''}
                                  </Flex>
                                }
                                w="175px"
                                placement="top"
                                bg="success.1000"
                                color="white"
                              >
                                <Flex
                                  fontSize="14px"
                                  alignItems="center"
                                  gap="2px"
                                >
                                  <Flex
                                    alignItems="center"
                                    lineHeight="0"
                                    mt="1.5px"
                                  >
                                    {getDaysRemaining(
                                      new Date(),
                                      new Date(job.jobads?.[0]?.job_expiry)
                                    )}
                                  </Flex>
                                  <Icon
                                    fontSize="12px"
                                    lineHeight="0"
                                    as={BiAlarm}
                                  />
                                </Flex>
                              </Tooltip>
                            </Flex>
                          ) : (
                            <Flex
                              color="warn.1000"
                              fontSize="20px"
                              alignItems="center"
                              gap="8px"
                              onClick={() => handleOpenPaid(job.job)}
                            >
                              <img
                                src={warnmarkCircle}
                                alt="Closemark Circle"
                                style={{
                                  width: '18px',
                                  height: '18px',
                                  objectFit: 'contain',
                                }}
                              />
                              <Tooltip
                                hasArrow
                                label={
                                  <Flex textAlign="center">
                                    The Linkedin job is expired
                                  </Flex>
                                }
                                w="175 px"
                                placement="top"
                                bg="warn.1000"
                                color="white"
                              >
                                <Flex
                                  fontSize="14px"
                                  alignItems="center"
                                  gap="2px"
                                >
                                  <Flex
                                    alignItems="center"
                                    lineHeight="0"
                                    mt="1.5px"
                                  >
                                    0
                                  </Flex>
                                  <Icon fontSize="12px" as={BiAlarm} />
                                </Flex>
                              </Tooltip>
                            </Flex>
                          )
                        ) : (
                          <Box
                            color="default.gray.1000"
                            w="18px"
                            h="18px"
                            onClick={() => handleOpenPaid(job.job)}
                          >
                            <img
                              src={xmarkCircle}
                              alt="Closemark Circle"
                              style={{
                                width: '18px',
                                height: '18px',
                                objectFit: 'contain',
                              }}
                            />
                          </Box>
                        )
                      ) : (
                        <Box
                          color="default.gray.1000"
                          w="18px"
                          h="18px"
                          onClick={() => handleOpenPaid(job.job)}
                        >
                          <img
                            src={xmarkCircle}
                            alt="Closemark Circle"
                            style={{
                              width: '18px',
                              height: '18px',
                              objectFit: 'contain',
                            }}
                          />
                        </Box>
                      )
                    ) : (
                      <Box color="default.gray.1000" w="18px" h="18px">
                        <img
                          src={xmarkCircle}
                          alt="Closemark Circle"
                          style={{
                            width: '18px',
                            height: '18px',
                            objectFit: 'contain',
                          }}
                        />
                      </Box>
                    )}
                  </Td>
                  <Td onClick={() => handleClick(job.job)}>
                    {job?.job?.blasted}
                  </Td>
                  <Td onClick={() => handleClick(job.job)}>{job.screen}</Td>
                  <Td onClick={() => handleClick(job.job)}>{job.submits}</Td>
                  <Td onClick={() => handleClick(job.job)}>{job.interview}</Td>
                  <Td
                    width="50px"
                    p="5px 24px"
                    fontSize="14px"
                    onClick={() => handleClick(job.job)}
                  >
                    {job.job.is_campaign ? (
                      <Badge
                        sx={{
                          borderRadius: '6px',
                          p: '2px 8px',
                          textTransform: 'capitalize',
                        }}
                        colorScheme="green"
                      >
                        Active
                      </Badge>
                    ) : (
                      <Badge
                        sx={{
                          borderRadius: '6px',
                          p: '2px 8px',
                          textTransform: 'capitalize',
                        }}
                        colorScheme="red"
                      >
                        Inactive
                      </Badge>
                    )}
                  </Td>
                  <Td onClick={() => handleManualParse(Number(job.id))}>
                    {job?.job.resume_count > 0 ? (
                      <Flex
                        bg="caution.100"
                        borderRadius="2px"
                        color="caution.400"
                        py="1"
                        px="2"
                        width="fit-content"
                      >
                        {job.job.resume_count}
                      </Flex>
                    ) : (
                      <>{job.job.resume_count}</>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      <Pagination
        totalPages={jobPage.lastPage}
        currentPage={jobPage.currentPage}
        onPageChange={(page) => pageChange(page)}
        onEntryChange={(entries) => entryChange(entries)}
        totalEntries={jobPage.count}
        currentCount={jobs?.length}
        targetCount={jobPgBtn.take}
      />

      {isOpenDrawer && (
        <JobsDrawer
          isOpen={isOpenDrawer}
          onClose={() => {
            onCloseDrawer();
            handleUpdateJobList();
          }}
          rowId={rowId}
          totalJobs={jobs?.length}
          handlePrevData={handlePrevData}
          handleNextData={handleNextData}
        />
      )}

      {isFollowUpOpen && (
        <FollowUpModal
          isOpen={isFollowUpOpen}
          onClose={() => {
            onFollowUpClose();
            setIsFollowUp(false);
          }}
          isAll={isFollowUpAll}
          job_id={followUpParam.job_id}
        />
      )}

      {isManualResumeOpen && (
        <ManualResumeDrawer
          isOpen={isManualResumeOpen}
          onClose={onManualResumeClose}
          job_id={jobId}
        />
      )}

      {isOpenPaid && <PaidJobModal isOpen={isOpenPaid} onClose={onClosePaid} />}
    </Stack>
  );
}

function CreateJobBtn({ onSubmit }: { onSubmit: (data: any) => Promise<any> }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button leftIcon="plus" variant="solid" onClick={onOpen}>
        Create New Job
      </Button>

      {isOpen && (
        <AddJobForm
          isOpen={isOpen}
          onClose={onClose}
          onSaveDraft={onSubmit}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}
function EditJobBtn({
  onSubmit,
  jobId,
}: {
  onSubmit: (data: any) => Promise<any>;
  jobId: number;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <MenuItem onClick={onOpen}>
        <Flex alignItems="center" justifyContent="space-between">
          <FAIcon iconName="pencil" />
          <Box ml={12} cursor="pointer">
            Edit
          </Box>
        </Flex>
      </MenuItem>
      {isOpen && (
        <PreFilledJobForm
          isOpen={isOpen}
          jobId={jobId}
          onClose={onClose}
          onSaveDraft={onSubmit}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}
function DuplicateJobBtn({
  onSubmit,
  jobId,
}: {
  onSubmit: (data: any) => Promise<any>;
  jobId: number;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <MenuItem onClick={onOpen}>
        <Flex alignItems="center" justifyContent="space-between">
          <FAIcon iconName="copy" />
          <Box ml={12} cursor="pointer">
            Duplicate
          </Box>
        </Flex>
      </MenuItem>
      {isOpen && (
        <PreFilledJobForm
          isOpen={isOpen}
          jobId={jobId}
          isDuplicate
          onClose={onClose}
          onSaveDraft={onSubmit}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}
