import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import AtsSelect from 'components/app/AtsSelect';
import AtsSelect2 from 'components/app/AtsSelect2';
import FAIcon from 'components/lib/FAIcon';
import { Field, FormikErrors } from 'formik';
import { useEffect, useState } from 'react';
import QueryInputs from './QueryInputs';

interface addCompInt {
  index?: number;
  handleChange: (e: any) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<any>>;
  criteria: any;
  candidate_filter_columns: any;
  condition: any;
  filter_operators: any;
  query: any;
  mod: any;
  values: any;
  setHasOperator: (e: any) => void;
  handleQueryValue: (e: any) => void;
  remove: (e: any) => void;
}

const FilterInputs = ({
  index,
  handleChange,
  setFieldValue,
  criteria,
  candidate_filter_columns,
  condition,
  filter_operators,
  query,
  mod,
  values,
  setHasOperator,
  handleQueryValue,
  remove,
}: addCompInt) => {
  const [criteriaState, setCriteriaState] = useState(
    mod?.criteria?.label || 'input'
  );
  const [criteriaOption, setCriteriaOption] = useState([]);

  useEffect(() => {
    let options: any = [];
    if (candidate_filter_columns.length > 0) {
      let groupings: any = [];
      candidate_filter_columns.map((item: any) => {
        if (!groupings[item.group]) {
          groupings[item.group] = [];
        }
        groupings[item.group].push(item);
      });
      const groupArr = Object.keys(groupings);
      groupArr.map((item: any) => {
        const fLet = item.charAt(0).toUpperCase();
        const label = fLet + item.slice(1);
        options.push({
          label,
          options: groupings[item],
        });
      });
      setCriteriaOption(options);
    }
  }, [candidate_filter_columns]);

  const rangeBoolean = (name: any) => {
    let bool = false;
    if (name) {
      if (name === 'BETWEEN' || name === 'NOT BETWEEN') {
        bool = true;
      }
    }
    return bool;
  };

  const [isRange, setIsRange] = useState(
    rangeBoolean(mod?.condition?.name) || false
  );
  const setOperators = (range = false) => {
    let options: any = [];
    if (filter_operators?.length > 0) {
      filter_operators.map((item: any) => {
        if (range === item.range) {
          options.push(item);
        }
      });
    }

    return options;
  };

  const [defOperators, setDefOperators] = useState(
    setOperators(mod?.criteria?.range || false)
  );

  const formatWorkStatus = (value: any) => {
    let options: any = value;

    if (
      mod?.criteria?.label === 'Resume Available' ||
      mod?.criteria?.label === 'Campaign Status' ||
      mod?.criteria?.label === 'DNC'
    ) {
      options = [
        {
          label: value,
          value: value,
        },
      ];
    }
    // setFieldValue(name, options);
    return options;
  };

  const criteriaChange = (e: any) => {
    setCriteriaState(e.label);
    setFieldValue(criteria, e);
    setDefOperators(setOperators(e.range));
  };
  useEffect(() => {
    if (mod?.criteria?.label) {
      setCriteriaState(mod?.criteria?.label);
    }
  }, [mod?.criteria?.label]);

  return (
    <Box className="row" key={`recr-${index}`}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        gap="16px"
        fontSize="14px"
      >
        <Box
          borderRadius="100%"
          border="1px solid purple"
          width="25px"
          height="25px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          {index + 1}
        </Box>

        <FormControl width="180px">
          <Field
            name={criteria}
            id={criteria}
            options={criteriaOption}
            component={AtsSelect2}
            value={criteria}
            onChange={(e: any) => criteriaChange(e)}
          />
          <FormErrorMessage>{String(criteria)}</FormErrorMessage>
        </FormControl>

        <FormControl width="140px">
          <Field
            name={condition}
            id={condition}
            options={defOperators.length > 0 && defOperators}
            component={AtsSelect2}
            value={condition}
            onChange={(e: any) => {
              setFieldValue(condition, e);
              handleQueryValue(e);
              const range =
                e.name === 'BETWEEN' || e.name === 'NOT BETWEEN' ? true : false;
              setIsRange(range);
            }}
          />
          <FormErrorMessage>{String(condition)}</FormErrorMessage>
        </FormControl>

        <FormControl sx={{ width: '160px' }}>
          <QueryInputs
            criteria={criteriaState}
            name={query}
            value={formatWorkStatus(mod.query)}
            handleChange={handleChange}
            disabled={mod.condition?.value === 3 || mod.condition?.value === 4}
            setFieldValue={setFieldValue}
            isRange={isRange}
          />

          <FormErrorMessage>{String(query)}</FormErrorMessage>
        </FormControl>

        {values.module.length > 1 && (
          <Button
            onClick={() => {
              setHasOperator((prevOperators: any) =>
                prevOperators
                  .filter((operator: { id: number }) => operator.id !== index)
                  .map((operator: any, ind: any) => ({
                    ...operator,
                    id: ind,
                  }))
              );
              remove(index);
            }}
          >
            <FAIcon iconName="minus" />
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default FilterInputs;
