import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  Th,
  Link,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import {
  useBreakDownActivityListMutation,
  useDayBreakActivityListMutation,
} from 'store/dashboard.slice';
import { boolean } from 'yup';
import TrLoading from '../TrLoading';
import { Link as RouterLink } from 'react-router-dom';
import toUrl from 'utils/toUrl';

interface DrawerProps {
  onClose: () => void;
  isOpen: boolean;
  type: string;
  selectByUser: any;
  week: number | null;
  date: any | null;
}

interface listInt {
  created_at: string;
  first_name: string;
  id: number;
  last_name: string;
  ref_id: number;
  status: string;
  type: string;
  parent_id: string;
  title: string;
  client_name?: string;
  start_date?: string;
  interview_round?: number;
  interview_date?: string;
  client_job_link?: string;
  client_job_title?: string;
  jobs?: number;
  submits?: number;
  lead_created_at?: string;
}

const ActivityDrawer = ({
  onClose,
  isOpen,
  type,
  selectByUser,
  week,
  date,
}: DrawerProps) => {
  const toTitleCase = (text: string) => {
    return text.replace(/\b(\w)/g, (s) => s.toUpperCase());
  };
  const [list, setList] = useState<listInt[]>([]);
  const [reqList, resList] = useBreakDownActivityListMutation();
  const [dayReqList, resDayList] = useDayBreakActivityListMutation();

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchWeekly = async () => {
    const param = {
      week,
      type,
      recruiters: selectByUser,
    };
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    reqList({ data: param, signal: abortControllerRef.current.signal })
      .then((res: any) => {
        setList(res?.data?.data || []);
      })
      .catch((e: any) => {});
  };

  const fetchDaily = async () => {
    const param = {
      date,
      type,
      recruiters: selectByUser,
    };
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    dayReqList({ data: param, signal: abortControllerRef.current.signal })
      .then((res: any) => {
        setList(res?.data?.data || []);
      })
      .catch((e: any) => {});
  };

  useEffect(() => {
    if (isOpen) {
      if (week) {
        fetchWeekly();
      }
      if (date) {
        fetchDaily();
      }
    }
  }, [isOpen]);

  const thSx = {
    // position: 'sticky',
    left: 0,
    bg: 'default.white.600',
    color: 'default.gray.600',
  };

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="md">
      <DrawerOverlay />
      <DrawerContent
        maxW="70vw"
        fontFamily="NunitoSans Regular"
        position="relative"
      >
        <DrawerCloseButton
          position="absolute"
          top="0px"
          left="-40px"
          borderRadius="unset"
          p="20px"
          bg="#E6E9EE"
          color="default.secondarytext"
          _hover={{
            backgroundColor: 'none',
          }}
        />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap="18px"
          pl={4}
          pr={0}
          borderBottom="1px solid #E6E9EE"
          fontSize="14px"
        >
          <Box
            id={`tagTooltip`}
            color="primary.600"
            fontSize="12px"
            lineHeight="18px"
            fontWeight={500}
            height="fit-content"
          >
            <Flex gap="5px" sx={{ paddingTop: '11px', paddingBottom: '11px' }}>
              <Link rel="noreferrer">{toTitleCase(type)}</Link>
            </Flex>
          </Box>
        </Flex>
        <DrawerBody p="0" overflow="hidden">
          <TableContainer
            boxSizing="border-box"
            border="1px solid #EEEEEE"
            borderRadius="4px"
            height="85vh"
            sx={{ overflowY: 'auto', padding: '1em 2em' }}
          >
            <Table>
              <Thead
                // sx={{ position: 'sticky', top: 0, zIndex: 1 }}
                key={`thead-candidate-0`}
              >
                <Tr bg="default.white.600">
                  {[
                    'new leads repeat',
                    'new leads talently',
                    'new client w/ interview',
                  ].includes(type) ? (
                    <>
                      <Th sx={thSx}>Lead Name</Th>
                      <Th sx={thSx}>Client Name</Th>
                    </>
                  ) : (
                    <>
                      <Th sx={thSx}>Candidate Name</Th>
                      <Th sx={thSx}>Job Name</Th>
                    </>
                  )}

                  {[
                    'submits',
                    'first interview',
                    'interviews',
                    'offers',
                    'placements',
                  ].includes(type) && (
                    <>
                      <Th sx={thSx}>Client Name</Th>
                    </>
                  )}

                  {[
                    'new leads repeat',
                    'new client w/ interview',
                    'new leads talently',
                  ].includes(type) && (
                    <>
                      <Th sx={thSx}>Client Job Link</Th>
                      <Th sx={thSx}>Jobs</Th>
                      <Th sx={thSx}>Submits</Th>
                    </>
                  )}

                  {['placements'].includes(type) && (
                    <>
                      <Th sx={thSx}>Start Date</Th>
                    </>
                  )}

                  {['interviews', 'first interview'].includes(type) && (
                    <>
                      <Th sx={thSx}>Interview Round</Th>
                    </>
                  )}
                  {[
                    'interviews',
                    'new client w/ interview',
                    'first interview',
                  ].includes(type) && (
                    <>
                      <Th sx={thSx}>Interview Date</Th>
                    </>
                  )}

                  <Th sx={thSx}>Current Status</Th>

                  {[
                    'new leads repeat',
                    'submits',
                    'new leads talently',
                    'offers',
                  ].includes(type) && (
                    <>
                      <Th sx={thSx}>Last Activity</Th>
                    </>
                  )}
                  {[
                    'new leads repeat',
                    'new client w/ interview',
                    'new leads talently',
                  ].includes(type) && (
                    <>
                      <Th sx={thSx}>Created At</Th>
                    </>
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {resList.isLoading ? (
                  <TrLoading columns={4} rows={10} />
                ) : (
                  list.map((item: any) => {
                    return (
                      <Tr>
                        <Td>
                          {[
                            'new leads repeat',
                            'new leads talently',
                            'new client w/ interview',
                          ].includes(type) ? (
                            <Link
                              as={RouterLink}
                              to={`/clients/contacts/${item.ref_id}/overview`}
                            >
                              {[item.first_name, item.last_name]
                                .filter(boolean)
                                .join(' ')}
                            </Link>
                          ) : (
                            <Link
                              as={RouterLink}
                              to={`/candidates/search/${item?.candidate_id}/about?total=1&rowId=0&page=1&take=10 `}
                            >
                              {[item.first_name, item.last_name]
                                .filter(boolean)
                                .join(' ')}
                            </Link>
                          )}
                        </Td>
                        <Td>
                          {[
                            'new leads repeat',
                            'new client w/ interview',
                            'new leads talently',
                          ].includes(type) ? (
                            <Link
                              as={RouterLink}
                              to={`/clients/all-clients/${item.client_id}/overview`}
                            >
                              {item.client_name}
                            </Link>
                          ) : (
                            <Link
                              as={RouterLink}
                              to={`/jobs/${item.job_id}/details`}
                            >
                              {item.job_name}
                            </Link>
                          )}
                        </Td>

                        {[
                          'new leads repeat',
                          'new client w/ interview',
                          'new leads talently',
                        ].includes(type) && (
                          <>
                            <Td>
                              {item.client_job_link ? (
                                <>
                                  <Link
                                    href={toUrl(item.client_job_link)}
                                    target="_blank"
                                  >
                                    {item?.client_job_title ||
                                      item.client_job_link}
                                  </Link>
                                </>
                              ) : (
                                <>{item?.client_job_title || '-'}</>
                              )}
                            </Td>
                            <Td>{item.jobs}</Td>
                            <Td>{item.submits}</Td>
                          </>
                        )}

                        {[
                          'submits',
                          'first interview',
                          'interviews',
                          'offers',
                          'placements',
                        ].includes(type) && (
                          // Clickable come back here
                          <>
                            <Td>
                              <Link
                                as={RouterLink}
                                to={`/clients/all-clients/${item.client_id}/overview`}
                              >
                                {item.client_name}
                              </Link>
                            </Td>
                          </>
                        )}

                        {['placements'].includes(type) && (
                          <>
                            <Td>
                              {moment(item.start_date).format('MMM D, YYYY') ||
                                'start_date'}
                            </Td>
                          </>
                        )}

                        {['interviews', 'first interview'].includes(type) && (
                          <>
                            <Td>{item.interview_round || 2}</Td>
                          </>
                        )}
                        {[
                          'new client w/ interview',
                          'interviews',
                          'first interview',
                        ].includes(type) && (
                          <>
                            <Td>
                              {moment(item.interview_date).format(
                                'MMM D, YYYY'
                              ) || 'interview date'}
                            </Td>
                          </>
                        )}

                        <Td>{item.status}</Td>

                        {[
                          'new leads repeat',
                          'submits',
                          'new leads talently',
                          'offers',
                        ].includes(type) && (
                          <>
                            <Td>
                              {moment(item.created_at).format('MMM D, YYYY')}
                            </Td>
                          </>
                        )}
                        {[
                          'new leads repeat',
                          'new client w/ interview',
                          'new leads talently',
                        ].includes(type) && (
                          <>
                            <Td>
                              {moment(item.lead_created_at).format(
                                'MMM D, YYYY'
                              ) || 'lead_created_at'}
                            </Td>
                          </>
                        )}
                      </Tr>
                    );
                  })
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ActivityDrawer;
