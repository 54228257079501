import Table from 'modules/Leads/components/table/ExistingClientProspectsTable';
import { PROSPECTS } from 'modules/Leads/constants/filter-constants';
import { useFetchExistingClientProspectsQuery } from 'services/leads/leadsApi';
import { DateSort, LeadDetailDrawerContextType } from 'modules/Leads/types';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router';
import { resetFilter } from 'store/leads.slice';
import { Stack } from '@chakra-ui/react';
import {
  useAddLeadBulkTagsMutation,
  useSetBulkLeadsInactiveMutation,
} from 'services/leads/leads.mutation';
import { ConfirmWithReason } from 'utils/swal';
import { swalContent } from 'types';

let content: swalContent = {
  title: 'Are you sure?',
  text: 'You are about to mark this lead as inactive, continue?',
  buttons: ['Cancel', true],
  icon: 'warning',
};
export default function ExistingClientProspect() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { status, query, dateFilter, miles, tags, locations } = useSelector(
    (state: {
      leads: {
        status: number[];
        dateFilter: string;
        query: string;
        miles: { value: string; label: string };
        locations: Record<'value', number>[];
        tags: Array<{ value: number; label: string }>;
      };
    }) => state.leads
  );
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(50);
  const [sortOrder, setSortOrder] = useState<DateSort>('DESC');
  const { data, isFetching } = useFetchExistingClientProspectsQuery({
    unclaimed: false,
    is_prospect: 1,
    is_repeat_prospect: 1,
    types: [] as number[],
    hot: false,
    qualified: false,
    status,
    query,
    date_filter: dateFilter,
    page,
    take,
    orderBy: {
      column_name: 'created_at',
      sort_order: sortOrder,
    },
    zip_code: {
      distance: parseInt(miles?.value),
    },
    tags: tags?.map((tag) => tag.value),
    location: locations?.map((item) => ({ value: item?.value })),
  });
  const leadsList = data?.data?.reduce(
    (acc, curr) => acc.concat(curr.leads),
    []
  );
  const leadDetailDrawerRowId = leadsList?.findIndex(
    (lead) => lead.id === parseInt(params.leadsId)
  );
  const [reqSetBulkLeadsInActive] = useSetBulkLeadsInactiveMutation();
  const [reqAddBulkTags] = useAddLeadBulkTagsMutation();
  const drawerContext = useMemo(() => {
    return {
      rowId: leadDetailDrawerRowId + 1,
      totalLeads: data?.count,
      handlePrevData: handlePrevDrawerPagination,
      handleNextData: handleNextDrawerPagination,
      onClose: () => navigate('/leads/existing-client-prospects'),
    };
  }, [leadDetailDrawerRowId, data]);
  function handlePageChange(page: number) {
    setPage(page);
  }
  function handleEntriesChange(take: number) {
    setTake(take);
  }

  function handleDateSort() {
    setSortOrder((state) => (state === 'DESC' ? 'ASC' : 'DESC'));
  }
  async function handleNextDrawerPagination() {
    //  const res= await fetchLeads()
    if (leadsList.length === leadDetailDrawerRowId + 1) {
      //reached end of current table pagination data
    } else {
      const nextLeadDetail = leadsList[leadDetailDrawerRowId + 1];
      nextLeadDetail &&
        navigate(
          `/leads/prospects/existing-client-prospects/${nextLeadDetail?.id}/details`
        );
    }
  }
  function handlePrevDrawerPagination() {
    if (leadDetailDrawerRowId >= 0) {
      const prevLeadDetail = leadsList[leadDetailDrawerRowId - 1];
      prevLeadDetail &&
        navigate(
          `/leads/prospects/existing-client-prospects/${prevLeadDetail?.id}/details`
        );
    }
  }
  async function onBulkInActive(idList: number[]) {
    const submittedReason = await ConfirmWithReason(content);
    if (submittedReason) {
      await reqSetBulkLeadsInActive({
        data: { idList, inactive_reason: submittedReason },
      });
    }
  }
  async function onAddBulkTags(tags: Array<string>, ids: Array<number>) {
    try {
      const payload = await reqAddBulkTags({
        data: {
          tags: tags,
          idList: ids,
        },
      }).unwrap();
      return payload;
    } catch (err: any) {
      return err.data;
    }
  }
  useEffect(() => {
    const status = PROSPECTS[0].items
      .filter((stat) => stat.status)
      .map((item) => item.id);
    dispatch(resetFilter({ status }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      spacing={0}
      sx={{
        h: '100%',
        w: 'calc(100% - 320px)',
        bgColor: '#fff',
        borderRadius: '10px',
        p: '16px',
      }}
    >
      <Table
        onDateSort={handleDateSort}
        onSubmitEmail={() => {}}
        rows={data?.data ?? []}
        onBulkInactive={onBulkInActive}
        onAddTags={onAddBulkTags}
        isLoading={isFetching}
        pagination={{
          onEntryChange: handleEntriesChange,
          onPageChange: handlePageChange,
          currentCount: data?.data?.length,
          currentPage: page,
          targetCount: take,
          totalEntries: data?.count,
          totalPages: data?.lastPage,
        }}
      />

      {/* Render drawer */}
      <Outlet context={drawerContext satisfies LeadDetailDrawerContextType} />
    </Stack>
  );
}
