import * as Yup from 'yup';

// Reusable sub-schemas
const labelValueRequiredSchema = (name: string) =>
  Yup.object().shape({
    label: Yup.string().required(`${name} is required`),
    value: Yup.number(),
  });

const labelValueSchema = Yup.object().shape({
  label: Yup.string(),
  value: Yup.number(),
});

const emailSchema = Yup.object().shape({
  label: Yup.string(),
  value: Yup.string().email(),
});

const arrayOfLabelValue = Yup.array().of(labelValueSchema).nullable();

const recruiterSchema = Yup.object().shape({
  recruiter: labelValueRequiredSchema('Recruiter'),
  post_new_job_ads: Yup.boolean(),
  split: Yup.number().required('Split percentage is required'),
  review: Yup.string().oneOf([
    'no_submittal_review',
    'review_send_as_client_owner',
    'review_send_as_client_recruiter',
    '',
  ]),
  interview: Yup.string().oneOf([
    'interview_send_as_recruiter',
    'interview_send_as_client_owner',
    '',
  ]),
});
const clientDetailValidation = Yup.lazy(() =>
  Yup.object().shape({
    // Client Details
    job_type: Yup.string().oneOf(['1', '2']),
    client: Yup.object()
      .shape({
        label: Yup.string().required('Client is required'),
        value: Yup.string(),
      })
      .required('Client is required'),
    contactList: arrayOfLabelValue,
    emailList: Yup.array().of(emailSchema).nullable(),
    contact: Yup.object().shape({
      label: Yup.string().required('Pick at least 1 contact'),
      value: Yup.number(),
      title: Yup.string(),
    }),
    location: labelValueRequiredSchema('Location'),
    industry: labelValueRequiredSchema('Industry'),
    cc: emailSchema.nullable(),
    bcc: emailSchema.nullable(),
    company_website: Yup.string(), //require for placeholder
  })
);
const jobDetailValidation_full = Yup.lazy(() =>
  Yup.object().shape({
    // Job Details - Full Post
    client_job_title: Yup.string().required(
      'Client job opening title is required.'
    ),
    no_of_opening: Yup.number().when('job_type', {
      is: '1',
      then: Yup.number()
        .required('No. of openings is required.')
        .min(1, 'Invalid number.'),
      otherwise: Yup.number().notRequired(),
    }),
    experience_level: labelValueRequiredSchema('Experience level'),
    education: labelValueRequiredSchema('Education'),
    work_type: labelValueRequiredSchema('Work type'),
    job_link_type: Yup.object()
      .shape({
        label: Yup.string().matches(/^(https?:\/\/)/, 'Must be a valid URL'),
        value: Yup.string().matches(/^(https?:\/\/)/, 'Must be a valid URL'),
      })
      .nullable(),
    job_link_domain: Yup.string(),
    employment_type: labelValueRequiredSchema('Employment type'),
    compensation_type: labelValueRequiredSchema('Cmpensation type'),
    salary_range_start: Yup.string()
      .matches(/^[\d,]+$/gi, 'Salary is invalid')
      .required('Salary range start is required.')
      .test(
        'is-smaller',
        'Start salary must be less than end salary',
        function (value) {
          if (!value || !this?.parent?.salary_range_end) return true; // Skip validation if either field is empty
          const start = parseFloat(value?.replace(/,/g, ''));
          const end = parseFloat(
            this?.parent?.salary_range_end?.replace(/,/g, '')
          );
          return start < end;
        }
      ),
    salary_range_end: Yup.string()
      .matches(/^[\d,]+$/gi, 'Salary is invalid')
      .required('Salary range end is required.')
      .test(
        'is-greater',
        'End salary must be greater than start salary',
        function (value) {
          if (!value || !this?.parent?.salary_range_start) return true; // Skip validation if either field is empty
          const start = parseFloat(
            this?.parent?.salary_range_start?.replace(/,/g, '')
          );
          const end = parseFloat(value?.replace(/,/g, ''));
          return end > start;
        }
      ),
    skills: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string().required('At least one skill is required'),
        })
      )
      .min(1, 'At least one skill is required'),
    client_job_description: Yup.string().nullable(),
    client_note: Yup.string().nullable(),
  })
);
const jobDetailValidation_quick = Yup.lazy(() =>
  Yup.object().shape({
    // Job Details - Quick post
    account_manager: Yup.object().shape({
      label: Yup.string().required('Account manager is required'),
      value: Yup.number(),
    }),
    primary_recruiter: Yup.object().shape({
      label: Yup.string().required('Primary recruiter is required'),
      value: Yup.number(),
    }),
    client_job_title: Yup.string().required(
      'Client job opening title is required.'
    ),

    experience_level: labelValueRequiredSchema('Experience level'),
    skills: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string().required('At least one skill is required'),
        })
      )
      .min(1, 'At least one skill is required'),
    client_note: Yup.string().nullable(),
  })
);
const recruitmentValidation = Yup.object().shape({
  // Recruitment Team
  managerList: arrayOfLabelValue,
  account_manager: labelValueRequiredSchema('Account Manager'),
  primary_recruiter: labelValueRequiredSchema('Primary recruiter'),
  recruiters: Yup.array().of(recruiterSchema).nullable(),
});
const jobAdsValidation = Yup.object().shape({
  // Job Ads
  posting_title: Yup.string().required('Posting Title is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  country: Yup.object().shape({
    label: Yup.string().required('Country is required'),
    values: Yup.string(),
  }),
  zip_code: Yup.string().required('Zip Code is required'),
  career_page: Yup.boolean(),
  show_profile: Yup.boolean(),
  paid_job: Yup.boolean(),
  free_job: Yup.boolean(),
  job_description: Yup.string().required('Job description is required'),
});
const jobCampaignValidation_full = Yup.object().shape({
  // Job Campaign
  campaign_status: Yup.object().shape({
    label: Yup.string(),
    value: Yup.boolean(),
  }),
  subject: Yup.string(),
  body: Yup.string(),
});
const jobCampaignValidation_quick = Yup.object().shape({
  // Job Campaign
  subject: Yup.string(),
  body: Yup.string(),
});

export const fullJobValidationScheme = [
  clientDetailValidation,
  jobDetailValidation_full,
  recruitmentValidation,
  jobAdsValidation,
  jobCampaignValidation_full,
];
export const quickJobValidationScheme = [
  clientDetailValidation,
  jobDetailValidation_quick,
  jobCampaignValidation_quick,
];

type ClientDetailType = Yup.InferType<typeof clientDetailValidation>;
type JobDetailType = Yup.InferType<typeof jobDetailValidation_full>;
type RecruitmentType = Yup.InferType<typeof recruitmentValidation>;
type JobAdsType = Yup.InferType<typeof jobAdsValidation>;
type JobCampaignType = Yup.InferType<typeof jobCampaignValidation_full>;

export type IntialValueType = ClientDetailType &
  JobDetailType &
  RecruitmentType &
  JobAdsType &
  JobCampaignType;
