const follow_up_table = {
  subject: `Feedback on our {{client_job_title}} candidates`,
  title: `Follow-up`,
  subjectmultiple: `Feedback on Candidates`,
  bodymultiple: `
  <div id="follow-up-container">
    <table style="font-family: Verdana; font-size: 10pt; line-height: normal;" cellspacing="0" cellpadding="0">
      <thead>
        <tr style="font-family: Verdana; font-size: 10pt; line-height: normal;">
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;padding:2px; text-align :center;" valign="top" width="156">
            <strong >
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">Name</span>
              </strong>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;padding:2px; text-align :center;" valign="top" width="156">
            <strong>
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">Status</span>
              </strong>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;padding:2px; text-align :center;" valign="top" width="156">
             <strong >
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">Date</span>
              </strong>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;padding:2px; text-align :center;" valign="top" width="342">
            <strong >
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">Feedback (Interview / Reject / Need more info)</span>
              </strong>
          </td>
        </tr>
      </thead>
      <tbody style="font-family: Verdana; font-size: 10pt; line-height: normal;" id="fj-{{job_id}}">
        <tr>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;">
            <p class="MsoNormal" style="font-family: Verdana; font-size: 10pt; line-height: normal;" align="center">
              <strong style="font-family: Verdana; font-size: 10pt; line-height: normal;">
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">&nbsp;</span>
              </strong>
            </p>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;">
            <p class="MsoNormal" style="font-family: Verdana; font-size: 10pt; line-height: normal;" align="center">
              <strong style="font-family: Verdana; font-size: 10pt; line-height: normal;">
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">&nbsp;</span>
              </strong>
            </p>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;">
            <p class="MsoNormal" style="font-family: Verdana; font-size: 10pt; line-height: normal;" align="center">
              <strong style="font-family: Verdana; font-size: 10pt; line-height: normal;">
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">&nbsp;</span>
              </strong>
            </p>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;">
            <p class="MsoNormal" style="font-family: Verdana; font-size: 10pt; line-height: normal;" align="center">
              <strong style="font-family: Verdana; font-size: 10pt; line-height: normal;">
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">&nbsp;</span>
              </strong>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  
  </div>
  `,
  body: `
  <div id="follow-up-container">
    <table style="font-family: Verdana; font-size: 10pt; line-height: normal;" cellspacing="0" cellpadding="0">
      <thead>
        <tr style="font-family: Verdana; font-size: 10pt; line-height: normal;">
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;padding:2px; text-align :center;" valign="top" width="156">
            <strong >
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">Name</span>
              </strong>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;padding:2px; text-align :center;" valign="top" width="156">
            <strong>
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">Status</span>
              </strong>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;padding:2px; text-align :center;" valign="top" width="156">
             <strong >
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">Date</span>
              </strong>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;padding:2px; text-align :center;" valign="top" width="342">
            <strong >
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">Feedback (Interview / Reject / Need more info)</span>
              </strong>
          </td>
        </tr>
      </thead>
      <tbody style="font-family: Verdana; font-size: 10pt; line-height: normal;" id="fj-{{job_id}}">
        <tr>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;">
            <p class="MsoNormal" style="font-family: Verdana; font-size: 10pt; line-height: normal;" align="center">
              <strong style="font-family: Verdana; font-size: 10pt; line-height: normal;">
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">&nbsp;</span>
              </strong>
            </p>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;">
            <p class="MsoNormal" style="font-family: Verdana; font-size: 10pt; line-height: normal;" align="center">
              <strong style="font-family: Verdana; font-size: 10pt; line-height: normal;">
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">&nbsp;</span>
              </strong>
            </p>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;">
            <p class="MsoNormal" style="font-family: Verdana; font-size: 10pt; line-height: normal;" align="center">
              <strong style="font-family: Verdana; font-size: 10pt; line-height: normal;">
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">&nbsp;</span>
              </strong>
            </p>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;">
            <p class="MsoNormal" style="font-family: Verdana; font-size: 10pt; line-height: normal;" align="center">
              <strong style="font-family: Verdana; font-size: 10pt; line-height: normal;">
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">&nbsp;</span>
              </strong>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  
  </div>
  `,
  tr: `<tr>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black; padding:2px; text-align :center;">
            {{candidate_full_name}}
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black; padding:2px; text-align :center;">
             {{status}}
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black; padding:2px; text-align :center;">
            {{submitted_interview_date}}
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black;">
            <p class="MsoNormal" style="font-family: Verdana; font-size: 10pt; line-height: normal;" align="center">
            <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">&nbsp;</span>
             
            </p>
          </td>
        </tr>`,
  table: `<table style="font-family: Verdana; font-size: 10pt; line-height: normal;" cellspacing="0" cellpadding="0">
      <thead>
        <tr style="font-family: Verdana; font-size: 10pt; line-height: normal;">
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black; padding:2px;  text-align :center;" valign="top" width="156">
            <strong >
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">Name</span>
              </strong>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black; padding:2px;  text-align :center;" valign="top" width="156">
            <strong>
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">Status</span>
              </strong>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black; padding:2px;  text-align :center;" valign="top" width="156">
             <strong >
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">Date</span>
              </strong>
          </td>
          <td style="font-family: Verdana; font-size: 10pt; line-height: normal; border: 1px solid black; padding:2px;  text-align :center;" valign="top" width="342">
            <strong >
                <span style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">Feedback (Interview / Reject / Need more info)</span>
              </strong>
          </td>
        </tr>
      </thead>
      <tbody style="font-family: Verdana; font-size: 10pt; line-height: normal;" id="job-body">
        {{tbody}}
      </tbody>
    </table>
  `,
  table_container: `<div id="follow-up-container" >
     <div class="MsoNormal" style="font-family: Verdana; font-size: 10pt; line-height: normal;margin-bottom:5px; text-align : left;" >
     <p style="font-family: verdana, sans-serif; font-size: 10pt; line-height: normal;">
     <strong style="font-family: Verdana; font-size: 10pt; line-height: normal;">
          {{client_job_title}}
          </strong>
          </p>
      </div>
      <div>{{table_content}}</div>
  
  </div>`,
  placehoder_reference: [
    'job_id',
    'candidate_full_name',
    'status',
    'submitted_interview_date',
    'table_content',
    'tbody',
    'table_list',
  ],
};

export default follow_up_table;
